import React from "react";
import { Row, Col, Image, Card } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  EmailIcon,
  EmailShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";

function Articles() {
  return (
    <div>
      <h1>Articles</h1>
      <Card>
        <Card.Link
          href="https://lifewayvoices.com/culture-current-events/be-apocalyptic/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">Be Apocalyptic</Card.Title>
                <Card.Text id="clickable">
                  “I leave my house … and everyone is distant, no eye contact,
                  masks in place, shuffling along as quickly as possible as if
                  the very air we breathe could be poisoned. In the midst of
                  this kind of crisis … what does it mean to be apocalyptic?
                </Card.Text>
                <br />
                <FacebookShareButton url="https://lifewayvoices.com/culture-current-events/be-apocalyptic/">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://lifewayvoices.com/culture-current-events/be-apocalyptic/">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://lifewayvoices.com/culture-current-events/be-apocalyptic/">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://lifewayvoices.com/culture-current-events/be-apocalyptic/">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={process.env.PUBLIC_URL + "/Pictures/Be Apocalyptic.jpg"}
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Justin Burkholder
            <span className="articleDate">4 May 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://fessingapore.sharepoint.com/:b:/s/Curate/EXQm_WFJ1wpKviK6NdPO21cBY74DpGMkAaotmEaBIlTX8w?e=DFaZaG"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  Hard Truths or Hardened Hearts?
                </Card.Title>
                <Card.Text id="clickable">
                  A perspective on “the troubling instance of God hardening
                  Pharaoh's heart in the Exodus narratives”, and “that there are
                  enough parallels between ancient Egypt and contemporary
                  Singapore to facilitate a dialogue”.
                </Card.Text>
                <br />
                <br />
                <br />
                <FacebookShareButton url="https://fessingapore.sharepoint.com/:b:/s/Curate/EXQm_WFJ1wpKviK6NdPO21cBY74DpGMkAaotmEaBIlTX8w?e=DFaZaG">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://fessingapore.sharepoint.com/:b:/s/Curate/EXQm_WFJ1wpKviK6NdPO21cBY74DpGMkAaotmEaBIlTX8w?e=DFaZaG">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://fessingapore.sharepoint.com/:b:/s/Curate/EXQm_WFJ1wpKviK6NdPO21cBY74DpGMkAaotmEaBIlTX8w?e=DFaZaG">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://fessingapore.sharepoint.com/:b:/s/Curate/EXQm_WFJ1wpKviK6NdPO21cBY74DpGMkAaotmEaBIlTX8w?e=DFaZaG">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={
                  process.env.PUBLIC_URL +
                  "/Pictures/Hard Truths or Hardened Hearts.jpg"
                }
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Anonymous FES Graduate
            <span className="articleDate">25 May 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="http://www.scgm.org.sg/does-the-future-have-a-church/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  Does The Future Have A Church?
                  <br />
                  <small>
                    A leadership exercise for those who know Who holds tomorrow.
                  </small>
                </Card.Title>
                <Card.Text id="clickable">
                  “Some thoughts on how your church or organisation can be more{" "}
                  <b>
                    <i>future-ready</i>
                  </b>{" "}
                  and{" "}
                  <b>
                    <i>future-faithful</i>
                  </b>
                  . By ready, we don’t mean having an answer to everything….
                  Rather it’s about having a community that is resilient against
                  challenges and having the capacity to adapt to changes.”
                </Card.Text>
                <br />
                <FacebookShareButton url="http://www.scgm.org.sg/does-the-future-have-a-church/">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="http://www.scgm.org.sg/does-the-future-have-a-church/">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="http://www.scgm.org.sg/does-the-future-have-a-church/">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="http://www.scgm.org.sg/does-the-future-have-a-church/">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={
                  process.env.PUBLIC_URL +
                  "/Pictures/Does The Future Have A Church.jpg"
                }
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Ng Zhi Wen
            <span className="articleDate">15 May 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://www.micahglobal.org/sites/default/files/doc/page/flyer_reimaging_church__mission_webinars.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  Reimagining Church & Mission
                </Card.Title>
                <Card.Text id="clickable">
                  COVID-19 catalyses change – what does this mean for church and
                  mission? … Technology is rapidly opening doors and improving
                  access. Alongside this alarm bells ring. Will there be great
                  competition? What impact will the virtual engagement have? How
                  does this affect mission?
                  <br />
                  <br />
                  An invitation to a series of webinars on Reimagining Church &
                  Mission facilitated by All Nations Christian College
                </Card.Text>
                <FacebookShareButton url="https://www.micahglobal.org/sites/default/files/doc/page/flyer_reimaging_church__mission_webinars.pdf">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://www.micahglobal.org/sites/default/files/doc/page/flyer_reimaging_church__mission_webinars.pdf">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://www.micahglobal.org/sites/default/files/doc/page/flyer_reimaging_church__mission_webinars.pdf">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://www.micahglobal.org/sites/default/files/doc/page/flyer_reimaging_church__mission_webinars.pdf">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={
                  process.env.PUBLIC_URL +
                  "/Pictures/Reimagining Church & Mission.jpg"
                }
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">All Nations</small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://www.gordonconwell.edu/blog/attentiveness-repent-and-restart/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  Attentiveness: Repent and Restart (Part I)
                </Card.Title>
                <Card.Text id="clickable">
                  All of higher education is in an upheaval at this time….
                  <br />
                  “How are we going to make it financially?”
                  <br />
                  “How can we do education without gathering together?”
                  <br />
                  “What will the new ‘normal’ look like next year?”
                  <br />
                </Card.Text>
                <FacebookShareButton url="https://www.gordonconwell.edu/blog/attentiveness-repent-and-restart/">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://www.gordonconwell.edu/blog/attentiveness-repent-and-restart/">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://www.gordonconwell.edu/blog/attentiveness-repent-and-restart/">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://www.gordonconwell.edu/blog/attentiveness-repent-and-restart/">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={
                  process.env.PUBLIC_URL +
                  "/Pictures/Attentiveness- Repent and Restart (Part 1).jpg"
                }
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Dr. Scott W. Sunquist
            <span className="articleDate">4 May 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://journal.praxislabs.org/designing-for-a-different-future-pressure-practices-b2a703e627df"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  Designing for a Different Future: Pressure & Practices
                </Card.Title>
                <Card.Text id="clickable">
                  For the past few months, all of us have undergone
                  unprecedented changes in our lives – changes that have likely
                  caused “a massive stress test for our emotional, spiritual,
                  and physical health.”
                  <br />
                  “What have we learned from the stress test we have all
                  endured? And how can we become fit for the challenges ahead?”
                  <br />
                  Read more about this from Pressure & Practices, the first in a
                  three-part series from the 2020 Praxis Redemptive Imagination
                  Summit.
                </Card.Text>
                <FacebookShareButton url="https://journal.praxislabs.org/designing-for-a-different-future-pressure-practices-b2a703e627df">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://journal.praxislabs.org/designing-for-a-different-future-pressure-practices-b2a703e627df">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://journal.praxislabs.org/designing-for-a-different-future-pressure-practices-b2a703e627df">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://journal.praxislabs.org/designing-for-a-different-future-pressure-practices-b2a703e627df">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                style={{ paddingTop: "30px" }}
                width="100%"
                src={
                  process.env.PUBLIC_URL + "/Pictures/Pressure & Practices.jpeg"
                }
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Andy Crouch and Dave Blanchard
            <span className="articleDate">7 May 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://www.christianitytoday.com/edstetzer/2020/may/short-term-and-long-term-effects-of-covid-19-on-church-in-a.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  The Short-Term and Long-Term Effects of COVID-19
                </Card.Title>
                <Card.Text id="clickable">
                  “What are the short-term and long-term effects of COVID-19 on
                  the church? As we slowly emerge out from sheltering in place
                  to a “new” normal, what will the new normal look like …?”
                  <br />
                  <br />
                  Though the context of the article is for the church in
                  America, we can similarly ask ourselves,{" "}
                  <b>
                    what will the new normal look like for FES and the student
                    ministry beyond the Circuit Breaker period?
                  </b>
                </Card.Text>
                <FacebookShareButton url="https://www.christianitytoday.com/edstetzer/2020/may/short-term-and-long-term-effects-of-covid-19-on-church-in-a.html">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://www.christianitytoday.com/edstetzer/2020/may/short-term-and-long-term-effects-of-covid-19-on-church-in-a.html">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://www.christianitytoday.com/edstetzer/2020/may/short-term-and-long-term-effects-of-covid-19-on-church-in-a.html">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://www.christianitytoday.com/edstetzer/2020/may/short-term-and-long-term-effects-of-covid-19-on-church-in-a.html">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                style={{ paddingTop: "25px" }}
                width="100%"
                src={
                  process.env.PUBLIC_URL +
                  "/Pictures/The Short-Term and Long-Term Effects of COVID-19.png"
                }
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Josh Laxton
            <span className="articleDate">6 May 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://www.scgm.org.sg/the-post-covid-19-landscape-for-global-missions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  The Post-Covid-19 Landscape For Missions
                </Card.Title>
                <Card.Text id="clickable">
                  “The pandemic has created a sense of urgency among believers.
                  I know of young people who are thinking about what they will
                  do with their lives... Both the world and the church must
                  resist the temptation of going back to previous comfort levels
                  and the status quo... When the pandemic ends, what are the
                  implications for world missions?”
                </Card.Text>
                <FacebookShareButton url="https://www.scgm.org.sg/the-post-covid-19-landscape-for-global-missions/">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://www.scgm.org.sg/the-post-covid-19-landscape-for-global-missions/">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://www.scgm.org.sg/the-post-covid-19-landscape-for-global-missions/">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://www.scgm.org.sg/the-post-covid-19-landscape-for-global-missions/">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={
                  process.env.PUBLIC_URL +
                  "/Pictures/The Post-Covid-19 Landscape for Missions.jpg"
                }
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Bishop Emeritus Robert Solomon
            <span className="articleDate">24 April 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://sojo.net/articles/how-cope-covid-19-grief-mother-s-day"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  How to cope with Covid-19 grief this Mother's Day
                </Card.Title>
                <Card.Text id="clickable">
                  Mother’s Day falls on 10 May. None of us imagined we’d spend
                  this day helplessly taming a pandemic. Even in “normal”
                  circumstances, this day highlights not only gifts but pain:
                  infertility, loss…. But in a year when our bodies, minds,
                  spirits, hope, and patience are worn thin, Mother’s Day 2020
                  triggers unprecedented challenges.
                </Card.Text>
                <FacebookShareButton url="https://sojo.net/articles/how-cope-covid-19-grief-mother-s-day">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://sojo.net/articles/how-cope-covid-19-grief-mother-s-day">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://sojo.net/articles/how-cope-covid-19-grief-mother-s-day">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://sojo.net/articles/how-cope-covid-19-grief-mother-s-day">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={
                  process.env.PUBLIC_URL +
                  "/Pictures/How to cope with Covid-19 grief this Mother's Day.jpg"
                }
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            J.Dana Trent
            <span className="articleDate">5 May 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://faithandleadership.com/nathan-kirkpatrick-how-think-about-whats-next-when-future-unclear"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  How to think about what's next when the future is unclear
                </Card.Title>
                <Card.Text id="clickable">
                  COVID-19 presents us with overwhelming daily challenges, but
                  we must also begin to consider what's ahead … But what will
                  that life look like?
                </Card.Text>
                <br />
                <FacebookShareButton url="https://faithandleadership.com/nathan-kirkpatrick-how-think-about-whats-next-when-future-unclear">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://faithandleadership.com/nathan-kirkpatrick-how-think-about-whats-next-when-future-unclear">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://faithandleadership.com/nathan-kirkpatrick-how-think-about-whats-next-when-future-unclear">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://faithandleadership.com/nathan-kirkpatrick-how-think-about-whats-next-when-future-unclear">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={
                  process.env.PUBLIC_URL +
                  "/Pictures/How to think about what's next when the future is unclear.png"
                }
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Nathan Kirkpatrick
            <span className="articleDate">28 April 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://www.barna.com/research/measure-digital-engagement/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  Measure What Matters: Faith Leaders Share How to Gauge Digital
                  Engagement
                </Card.Title>
                <Card.Text id="clickable">
                  “I think one of the biggest paradigm shifts.… is that if
                  you’re looking just at attendance numbers, IP addresses or
                  Facebook watch numbers right now, you’re just trying to
                  measure content to consumer. … In the Church, that’s not
                  really our objective of discipling people.”
                </Card.Text>
                <FacebookShareButton url="https://www.barna.com/research/measure-digital-engagement/">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://www.barna.com/research/measure-digital-engagement/">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://www.barna.com/research/measure-digital-engagement/">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://www.barna.com/research/measure-digital-engagement/">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={
                  process.env.PUBLIC_URL + "/Pictures/Measure What Matters.jpg"
                }
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Barna Group
            <span className="articleDate">21 April 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://curtthompsonmd.com/a-body-of-work/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">A Body of Work</Card.Title>
                <Card.Text id="clickable">
                  Are we suffering from Zoom fatigue?
                  <br />
                  <br />
                  Irritability. No fever, cough or body aches necessary. Just
                  the normal … symptoms of social distancing … after all the
                  online conference videocalls, we are far more tired than
                  usual. And as it turns out, there is a good reason for this.
                </Card.Text>
                <br />
                <br />
                <br />
                <FacebookShareButton url="https://curtthompsonmd.com/a-body-of-work/">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://curtthompsonmd.com/a-body-of-work/">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://curtthompsonmd.com/a-body-of-work/">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://curtthompsonmd.com/a-body-of-work/">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={process.env.PUBLIC_URL + "/Pictures/A Body of Work.png"}
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Curt Thompson
            <span className="articleDate">15 April 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://hbr.org/2020/04/3-tips-to-avoid-wfh-burnout"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  3 Tips to Avoid WFH Burnout
                </Card.Title>
                <Card.Text id="clickable">
                  While working from home, the lines between work and non-work
                  are blurring in new and unusual ways, and … we are likely to
                  struggle to preserve healthy boundaries between their
                  professional and personal lives.
                </Card.Text>
                <br />
                <FacebookShareButton url="https://hbr.org/2020/04/3-tips-to-avoid-wfh-burnout">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://hbr.org/2020/04/3-tips-to-avoid-wfh-burnout">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://hbr.org/2020/04/3-tips-to-avoid-wfh-burnout">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://hbr.org/2020/04/3-tips-to-avoid-wfh-burnout">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={process.env.PUBLIC_URL + "/Pictures/Burnout.jpg"}
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Laura M. Giurge and Vanessa K. Bohns
            <span className="articleDate">3 April 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="http://saltandlight.sg/news/if-weve-received-mercy-do-we-not-extend-mercy-the-last-resorts-kenneth-thong-on-how-we-can-be-agents-of-justice-in-a-desperate-time/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  If we’ve received mercy, do we not extend mercy?
                </Card.Title>
                <Card.Text id="clickable">
                  Isolation is a luxury for the privileged. How can we be agents
                  of justice in a desperate time?
                </Card.Text>
                <br />
                <br />
                <br />
                <FacebookShareButton url="http://saltandlight.sg/news/if-weve-received-mercy-do-we-not-extend-mercy-the-last-resorts-kenneth-thong-on-how-we-can-be-agents-of-justice-in-a-desperate-time/">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="http://saltandlight.sg/news/if-weve-received-mercy-do-we-not-extend-mercy-the-last-resorts-kenneth-thong-on-how-we-can-be-agents-of-justice-in-a-desperate-time/">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="http://saltandlight.sg/news/if-weve-received-mercy-do-we-not-extend-mercy-the-last-resorts-kenneth-thong-on-how-we-can-be-agents-of-justice-in-a-desperate-time/">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="http://saltandlight.sg/news/if-weve-received-mercy-do-we-not-extend-mercy-the-last-resorts-kenneth-thong-on-how-we-can-be-agents-of-justice-in-a-desperate-time/">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={process.env.PUBLIC_URL + "/Pictures/Mercy Article.jpg"}
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Gracia Lee
            <span className="articleDate">14 April 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://biologos.org/podcast-episodes/francis-collins-coronavirus-updates-with-nih-director"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  Science and Faith in Pandemic Times
                </Card.Title>
                <Card.Text id="clickable">
                  Video conversation with Francis Collins on how the church and
                  Christians might respond during this challenging time
                </Card.Text>
                <br />
                <br />
                <FacebookShareButton url="https://biologos.org/podcast-episodes/francis-collins-coronavirus-updates-with-nih-director">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://biologos.org/podcast-episodes/francis-collins-coronavirus-updates-with-nih-director">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://biologos.org/podcast-episodes/francis-collins-coronavirus-updates-with-nih-director">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://biologos.org/podcast-episodes/francis-collins-coronavirus-updates-with-nih-director">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={
                  process.env.PUBLIC_URL +
                  "/Pictures/Science and Faith in Pandemic Times.jpg"
                }
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Francis Collins
            <span className="articleDate">9 April 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://biologos.org/post/beating-boredom-science-and-faith-resources-for-isolation"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  Beating Boredom: Science and Faith Resources to Reduce
                  Isolation
                </Card.Title>
                <Card.Text id="clickable">
                  Bored? Fearful that this period will make recluses of us all?
                  … Here are some resources to stimulate our minds and hearts,
                  educate, entertain and inspire hope
                </Card.Text>
                <br />
                <br />
                <FacebookShareButton url="https://biologos.org/post/beating-boredom-science-and-faith-resources-for-isolation">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://biologos.org/post/beating-boredom-science-and-faith-resources-for-isolation">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://biologos.org/post/beating-boredom-science-and-faith-resources-for-isolation">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://biologos.org/post/beating-boredom-science-and-faith-resources-for-isolation">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={process.env.PUBLIC_URL + "/Pictures/Beating Boredom.jpg"}
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Biologos<span className="articleDate">31 March 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://www.regent-college.edu/about-us/news/2020/coronavirus-and-the-communion-of-the-saints"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  Coronavirus and the Communion of the Saints
                </Card.Title>
                <Card.Text id="clickable">
                  Looking at the Christian instinct to care from church history
                </Card.Text>
                <br />
                <br />
                <br />
                <FacebookShareButton url="https://www.regent-college.edu/about-us/news/2020/coronavirus-and-the-communion-of-the-saints">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://www.regent-college.edu/about-us/news/2020/coronavirus-and-the-communion-of-the-saints">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://www.regent-college.edu/about-us/news/2020/coronavirus-and-the-communion-of-the-saints">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://www.regent-college.edu/about-us/news/2020/coronavirus-and-the-communion-of-the-saints">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={
                  process.env.PUBLIC_URL +
                  "/Pictures/Coronavirus and the Communion of the Saints.png"
                }
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Bruce Hindmarsh
            <span className="articleDate">23 March 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="http://www.stephanierische.com/prisoners-of-hope/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">Prisoners of Hope</Card.Title>
                <Card.Text id="clickable">
                  What would it look like to be a prisoner of hope rather than a
                  prisoner of fear?
                </Card.Text>
                <br />
                <br />
                <br />
                <FacebookShareButton url="http://www.stephanierische.com/prisoners-of-hope/">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="http://www.stephanierische.com/prisoners-of-hope/">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="http://www.stephanierische.com/prisoners-of-hope/">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="http://www.stephanierische.com/prisoners-of-hope/">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={process.env.PUBLIC_URL + "/Pictures/Prisoners of Hope.jpg"}
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Stephanie Rische
            <span className="articleDate">2 April 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://www.sbc.edu.sg/racism-and-fear-tools-of-the-enemy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  Racism and Fear – Tools of the Enemy
                </Card.Title>
                <Card.Text id="clickable">
                  “Stop prejudice, the novel coronavirus has no race.”
                </Card.Text>
                <br />
                <br />
                <br />
                <FacebookShareButton url="https://www.sbc.edu.sg/racism-and-fear-tools-of-the-enemy/">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://www.sbc.edu.sg/racism-and-fear-tools-of-the-enemy/">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://www.sbc.edu.sg/racism-and-fear-tools-of-the-enemy/">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://www.sbc.edu.sg/racism-and-fear-tools-of-the-enemy/">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={process.env.PUBLIC_URL + "/Pictures/Racism and Fear.jpg"}
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">Andrew B Spurgeon</small>
        </Card.Footer>
      </Card>
      <br />
      <Card>
        <Card.Link
          href="https://saltandlight.sg/news/the-church-has-moved-online-and-the-elderly-are-falling-behind/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={8}>
              <Card.Body>
                <Card.Title id="clickable">
                  The church has moved online, and the elderly are falling
                  behind
                </Card.Title>
                <Card.Text id="clickable">
                  Plugging technology hurdles and lending a listening ear
                </Card.Text>
                <br />
                <br />
                <br />
                <FacebookShareButton url="https://saltandlight.sg/news/the-church-has-moved-online-and-the-elderly-are-falling-behind/">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://saltandlight.sg/news/the-church-has-moved-online-and-the-elderly-are-falling-behind/">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://saltandlight.sg/news/the-church-has-moved-online-and-the-elderly-are-falling-behind/">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://saltandlight.sg/news/the-church-has-moved-online-and-the-elderly-are-falling-behind/">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
            <Col sm={4}>
              <Image
                width="100%"
                src={
                  process.env.PUBLIC_URL +
                  "/Pictures/The church has moved online, and the elderly are falling behind.jpg"
                }
              />
            </Col>
          </Row>
        </Card.Link>
        <Card.Footer>
          <small className="text-muted">
            Gracia Lee<span className="articleDate">7 April 2020</span>
          </small>
        </Card.Footer>
      </Card>
      <br />
    </div>
  );
}

export default Articles;
