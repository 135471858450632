import React from "react";
import ReactPlayer from "react-player";
import { Image } from "react-bootstrap";

function Prayers() {
  return (
    <div>
      <h1>Lift Our Prayers</h1>
      <hr />
      <h3>Till now the LORD has helped us</h3>
      <h4 style={{ textAlign: "center" }}>
        <i>A prayer inspired by 1 Samuel 4-7</i>
      </h4>
      <a
        href={process.env.PUBLIC_URL + "/PDFs/Prayer Transcript 2.pdf"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p style={{ textAlign: "center" }}>Prayer Transcript</p>
      </a>
      <div className="audioWrapper">
        <ReactPlayer
          url={
            process.env.PUBLIC_URL +
            "/Audio/Till now the LORD has helped us.mp4"
          }
          className="react-player"
          controls
          style={{ left: "12.5%" }}
          width="75%"
          height="100%"
        />
      </div>
      <hr />
      <h3>Prayer of Confidence</h3>
      <Image
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
        src="./Pictures/Prayer of Confidence.jpg"
      />
      <hr />
      <h3>Pray for Students</h3>
      <Image
        style={{ width: "75%", left: "12.5%", position: "relative" }}
        src="/Pictures/LICC.jfif"
      />
      <br />
      <br />
      <p>
        The London Institute for Contemporary Christianity (LICC) compiles a
        list of prayers from students and young graduates. These may represent
        the unspoken anxieties that linger in the hearts of many other students
        and young graduates. Let us spend some time to pray for these concerns
        and hopes.
      </p>
      <h5>Give thanks...</h5>
      <p>Presence: Where do you sense God at work in this context?</p>
      <ul>
        <li>
          As a Christian student, it sometimes feels like a competition to do
          the most serving, do the most outreach, or have the busiest calendar.
          Coronavirus has stripped that away: we are all in the same boat and
          the desperation to be busy all the time is removed.
        </li>
        <li>
          With students brought out of the busyness of life and temporarily off
          the career ladder, they are reminded to draw closer to God in prayer,
          to evaluate what really matters in life.
        </li>
        <li>
          I still need to complete assignments despite the stress of this time.
          I see God’s presence in the peace he gives me to complete each task.
        </li>
      </ul>
      <h5>Ask for God’s help...</h5>
      <p>Pressures: What are the pressures affecting this context?</p>
      <ul>
        <li>
          There’s a lot of uncertainty around the future, including exams,
          qualifications, and employment (as there are very few organisations
          hiring). Some students feel isolated as they’re left behind whilst
          most have gone home. Loneliness and mental health have already been
          issues, and are compounded by Covid.
        </li>
        <li>
          Having graduations cancelled, and generally not having much to do or
          look forward to that feels purposeful, is difficult for many. It’s not
          at all how we’d have imagined we’d finish school or uni, and it feels
          like we won’t get the same celebration.
        </li>
        <li>
          We're still expected to complete all assessments despite having to
          self teach a lot. There’s also financial pressures, as rent still
          needs paying but my part-time job has stopped.
        </li>
      </ul>
      <h5>Intercede, praying God’s heart for these people and situations...</h5>
      <p>
        Purpose: What would you pray for God to do in this context to serve
        people in this season?
      </p>
      <ul>
        <li>
          As medical students have been accelerated through to join the NHS
          frontline, and many other students are free to volunteer and serve,
          pray they would bring extra provision where people and resources are
          stretched.
        </li>
        <li>
          Pray that God would bring clarity with regards to future years of
          study and career progression, as the lack of certainty is causing
          widespread anxiety.
        </li>
        <li>
          Pray that instead of competition and performance, there would be joy
          in learning and studying.
        </li>
      </ul>
      <hr />
      <h3>Personal Reflection - 100 days of Covid-19 in Singapore</h3>
      <div className="videoWrapper">
        <ReactPlayer
          style={{ left: "12.5%" }}
          width="75%"
          height="100%"
          url="https://youtu.be/JPx9f2Npw5A"
          className="react-player"
          controls
        />
      </div>
      <p>
        In the book of Habakkuk, the prophet had complained about God’s way of
        dealing with His people. Though he did not receive a satisfactory answer
        when he cried out for help, Habakkuk moved from complaining to having
        confidence in God. This is what he said in his book
      </p>
      <Image
        style={{ left: "6.25%", position: "relative" }}
        src="/Pictures/Habakkuk 3.jpg"
      />
      <br />
      <br />
      <p>
        During these 100 days since the first case of covid-19 emerged in
        Singapore, most of us would have complained, have been anxious or
        worried. As we look back to the happenings the past three months, let us
        take time to acknowledge our complaints and confess our unbelief. Let us
        also learn to assert our confidence in and dependence on the LORD, our
        Maker, Redeemer, and Sustainer.
      </p>
      <hr />
      <h3>Prayer of Confession and Repentance</h3>
      <p>
        As the number of confirmed cases among the migrant workers rapidly
        increases, we may find ourselves playing the blame game or quickly
        asking what we can do to help. Although the latter is good and proper,
        perhaps we could begin our response in prayer—a prayer of confession and
        repentance of our complicity in the reality that has befallen all of us.
        Here is how one has tried to do so:
      </p>
      <br />
      <div className="prayer">
        <br />
        <p>Lord, have mercy</p>
        <p>
          O God
          <br />
          We are your people
        </p>
        <p>
          But so often we suffer from amnesia
          <br />
          And forget how to live as your people
          <br />
          Instead we organize our lives around the language of efficiency,
          productivity and KPIs
        </p>
        <p>
          And now as the world comes both to a halt and goes into overdrive
          <br />
          It is a world that has become so…aberrant
          <br />
          That it is difficult to speak of efficiency, productivity and KPIs
          <br />
          But instead of death tolls, daily cases (local? foreign?) and
          (un)known clusters
        </p>
        <p>
          Dear God, in the midst of this dread and disorientation
          <br />
          There is a growing, glaring double reality between our community cases
          and foreign workers,
          <br />
          And in reading more of the plight of these workers,
          <br />
          Or the language that treats them as a threat to be contained
          <br />
          I am moved to grief, but also a sense of helplessness
          <br />I barely know how or what to pray, perhaps even how or what to
          truly feel,
        </p>
        <p>
          I could comfort myself uneasily that I had no part to play in this,
          <br />
          I am not the government
          <br />
          Nor am I a dorm operator
          <br />
          I am just ordinary folk,
          <br />
          Trying to do my job and live life
          <br />
          Though I do wonder about remote complicity
          <br />
          My comfortable blindness of the lives of those far removed from mine
          <br />
          And ultimately, my un-neighbourly silence
        </p>
        <p>Lord, have mercy</p>
        <p>
          And in this turning to you I am reminded that you! are a God
          <br />
          who spoke blessing to a foreigner in a strange land
          <br />
          and demands justice for the dispossessed
          <br />
          Liberty to the captives
          <br />
          A God of hesed, who became one of us
          <br />
          Who says love neighbour as self
        </p>
        <p>
          Forgive us, Father, for we have sinned against you.
          <br />
          For “we have not loved our neighbors,
          <br />
          and we have not heard the cry of the needy.”
        </p>
        <p>
          May we be a people who are quick to repent
          <br />
          —whether of our complicit ignorance or the temptation to find <br />
          our comfort in places that muffle the pleas for justice
          <br />
          May we be a people who are quick to examine self
          <br />
          —willing to confront uncomfortable truths about our lives and society
        </p>
        <p>
          Loom large in us
          <br />
          Even as we struggle to see and hear you amidst disarray
          <br />
          Even as we attempt to lament
          <br />
          And endeavour to give thanks,
          <br />
          May our lament not turn to bitter complaint
          <br />
          Nor our thanksgiving to obtuse displays of privilege
        </p>
        <p>
          May you renew our seeing and our hearing
          <br />
          So that in seeing, we may perceive you in our neighbour
          <br />
          and in hearing, we may discern the voices of those who cry out
        </p>
        <p>
          In our movement from the Friday of Lent to the Sunday of Easter,
          <br />
          may we (re-)learn to form our lives around wonder, love and praise
          <br />
          —new life in communion with you and neighbour
        </p>
        <p>
          Have mercy on us for
          <br />
          we are your people,
          <br />O God
        </p>
        <p>
          Christ, have mercy
          <br />
          Lord, have mercy
        </p>
        <br />
      </div>
      <hr />
      <br />
      <h3>Pray for Migrant Workers among Us</h3>
      <p>
        As we see the number of cases from migrant workers dormitories continues
        to increase, let’ spend about 10 minutes to listen to some conversations
        from the past week with a few of the migrant workers as we offer our
        prayers for them. Pray for their mental and physical wellbeing. Remember
        their families who are worried about them as well.
      </p>
      <div className="videoWrapper">
        <ReactPlayer
          style={{ left: "12.5%" }}
          width="75%"
          height="100%"
          url="https://youtu.be/Qis6_86DlVU"
          className="react-player"
          controls
        />
      </div>
      <hr />
      <h3>Lament Prayer for Today's World</h3>
      <p>
        Take time to be still before the Lord. Hear, contextualise, and pray
        this{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://soundcloud.com/regentcollege/prayer-lament-24-march-2020?in=regentcollege/sets/chapel-24-march-2020"
        >
          8-minute prayer of lament by Mary France Giles
        </a>
        , a student at Regent College to help you pray for the current
        condition. As you pray, remember your immediate community on campus,
        your family and friends, our country, and many people in the world who
        are greatly affected by the pandemic.
      </p>
      <h4 style={{ textAlign: "center" }}>
        Listen to this prayer adapted to the Singapore context
      </h4>
      <a
        href={process.env.PUBLIC_URL + "/PDFs/Prayer Transcript.pdf"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p style={{ textAlign: "center" }}>Prayer Transcript</p>
      </a>
      <div className="audioWrapper">
        <ReactPlayer
          url={process.env.PUBLIC_URL + "/Audio/Prayer of Lament.mp4"}
          className="react-player"
          controls
          style={{ left: "12.5%" }}
          width="75%"
          height="100%"
        />
      </div>
      <br />
    </div>
  );
}

export default Prayers;
