import React from "react";

function ScribblIO() {
  return (
    <div className="gameWrapper">
      <iframe
        title="ScribbleIO"
        id="iframehtml5"
        width="100%"
        height="100%"
        src="https://skribbl.io/"
        frameborder="0"
        border="0"
        scrolling="no"
        class="iframe-default"
        allowfullscreen=""
      ></iframe>
    </div>
  );
}

export default ScribblIO;
