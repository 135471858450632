import React from "react";
import ReactPlayer from "react-player";
import { Image, Row, Col, Card } from "react-bootstrap";

function Skills() {
  return (
    <div>
      <h1>Level Up Our Skills</h1>
      <hr />
      <h3>Engaging the Campus</h3>
      <div className="videoWrapper">
        <ReactPlayer
          style={{ left: "12.5%" }}
          width="75%"
          height="100%"
          url="https://youtu.be/RtDTsiyac58"
          className="react-player"
          controls
        />
      </div>
      <br />
      <p>
        Circuit Breaker will be eased in about a week, but things will not
        return to the normal that we know. It will take more creativity and
        imagination to engage the world, and specifically for Christian students
        to engage the places we study at. Let’s draw ideas and reflections on
        engagement through this{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://ifesworld.org/en/university/"
        >
          e-learning course from IFES
        </a>
        , which was launched in February 2020.
      </p>
      <Card style={{ width: "75%", left: "12.5%" }}>
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <small>
              God loves the university! Do you? How can you learn to see your
              university through God’s eyes? What would that look like as you
              engage with campus life and conversations? How would that
              perspective impact you in your particular discipline? The course
              will help you, as an individual and as a member of your local IFES
              community, to think biblically and critically about your context
              and your calling in the university. You will be equipped to seize
              the opportunities and tackle the challenges you meet as you follow
              Christ on campus, engaging the whole university, as students,
              faculty or campus staff.
            </small>
            <footer className="blockquote-footer">
              To sign up for the course or to find out more, email{" "}
              <a href="mailto:engagingtheuni@ifesworld.org">
                engagingtheuni@ifesworld.org
              </a>
              .
            </footer>
          </blockquote>
        </Card.Body>
      </Card>
      <br />
      <p>
        Two books published by FES, that are among the recommended resources,
        are now available in digital format. To purchase them online, go to the
        links given below.
        <br />
        <br />
        Let’s learn and imagine together on how Christian students can engage
        the campus.
      </p>
      <br />
      <Card style={{ width: "80%", left: "10%" }}>
        <Card.Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.kobo.com/sg/en/ebook/why-study"
        >
          <Row>
            <Col md={3} id="clickable">
              <Image
                style={{ width: "100%" }}
                src="/Pictures/Why Study.jpg"
              ></Image>
            </Col>
            <Col md={9} id="clickable">
              <Card.Header>
                Why Study? Exploring the Face of God in the Academy
                <br />
                <small>
                  <i>Denis R. Alexander, Priyan Dias, Ross McKenzie et al</i>
                </small>
              </Card.Header>
              <Card.Body>
                Why do I study? What is the connection between what I study in
                campus with my Christian faith? Ten Christians from different
                professions and walks of life share on how Christians can start
                to integrate their faith with what they study.
              </Card.Body>
            </Col>
          </Row>
        </Card.Link>
      </Card>
      <br />
      <Card style={{ width: "80%", left: "10%" }}>
        <Card.Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.kobo.com/sg/en/ebook/engaging-the-campus"
        >
          <Row>
            <Col md={3} id="clickable">
              <Image
                style={{ width: "100%" }}
                src="/Pictures/Engaging the Campus.jpg"
              ></Image>
            </Col>
            <Col md={9} id="clickable">
              <Card.Header>
                Engaging the Campus: Faith and Service in the Academy
                <br />
                <small>
                  <i>Terence Halliday, Vinoth Ramachandra et al</i>
                </small>
              </Card.Header>
              <Card.Body>
                What does it mean to engage the whole campus for the Christ?
                What are the challenges faced and possible blind spots
                discovered as we practise our faith and serve in the academy?
                Drawing lessons from Scripture and history, the contributors
                present how Christian students can learn to think about ways to
                practise their faith in campus life.
              </Card.Body>
            </Col>
          </Row>
        </Card.Link>
      </Card>
      <br />
      <hr />
      <h3>Online Learning Platforms</h3>
      <p>
        Why don’t you use this period to learn from these FREE online learning
        platforms:
      </p>
      <Row>
        <Col style={{ padding: "20px" }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.khanacademy.org/"
          >
            <Image
              style={{ width: "100%", left: "0", position: "relative" }}
              src="/Pictures/Khan Academy.png"
            />
            <p style={{ textAlign: "center" }}>
              <h4>
                <b>Khan Academy</b>
              </h4>
            </p>
            <p>
              Khan Academy offers online instructional videos and exercises that
              enable learners of any age to study at their own pace. It covers
              math, science, computer programming, history, art history,
              economics, and many more.
            </p>
          </a>
        </Col>
        <Col style={{ padding: "20px" }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.edx.org/"
          >
            <Image
              style={{ width: "100%", left: "0", position: "relative" }}
              src="/Pictures/edx.png"
            />
            <p style={{ textAlign: "center" }}>
              <h4>
                <b>edX</b>
              </h4>
            </p>
            <p>
              Edx brings wide range of subjects online from many top
              institutions. Their popular subjects include computer programming,
              language, data science, business and management, engineering, and
              humanities.
            </p>
          </a>
        </Col>
      </Row>
      <Row>
        <Col style={{ padding: "20px" }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://learndigital.withgoogle.com/digitalgarage"
          >
            <Image
              style={{ width: "100%", left: "0", position: "relative" }}
              src="/Pictures/Google Digital.png"
            />
            <p style={{ textAlign: "center" }}>
              <h4>
                <b>Google Digital Garage</b>
              </h4>
            </p>
            <p>
              Learn new skills for a digital world through free courses in Data
              and Technology, Digital Marketing, and Career Development with
              Google.
            </p>
          </a>
        </Col>
        <Col style={{ padding: "20px" }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ntuc.mygo1.com/"
          >
            <Image
              style={{ width: "100%", left: "0", position: "relative" }}
              src="/Pictures/NTUC Mygo1.png"
            />
            <p style={{ textAlign: "center" }}>
              <h4>
                <b>NTUC GO1</b>
              </h4>
            </p>
            <p>
              The library of free courses offered on the GO1 platform features
              some of the most in-demand Worker 4.0 skillsets under Adaptive
              Skills — such as Change Management, Business Management,
              Leadership and Innovation — and Technology Skills — such as Data
              Analytics, Python Coding, Blockchain and Cloud. Creative courses
              such as Adobe Photoshop and Graphic Design will also be offered.
            </p>
          </a>
        </Col>
      </Row>
      <hr />
      <h3>Building competence in meeting online</h3>
      <p>
        During this period, we arrange most, if not all, of our meetings through
        online platforms. <br />
        Let’s take a few minutes to remember the basics with{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://youtu.be/-nnME0T6tuU"
        >
          this video
        </a>
        .
      </p>
      <p>
        For those who are involved in teaching/facilitating Bible studies or
        business meetings, <br />
        learn some tips from a lecturer in Technology Enhanced Learning in{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://theconversation.com/coronavirus-14-simple-tips-for-better-online-teaching-133573"
        >
          this article
        </a>
        .
      </p>
      <p>
        And while you are staying at home, it may also be beneficial to equip
        yourself to be a better teacher/facilitator through{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.coursera.org/learn/teach-online"
        >
          this FREE online course
        </a>
        .
      </p>
      <hr />
      <h3>Making simple breakfast</h3>
      <p>
        Why don’t you learn a new skill during this period? Grab one packet of
        bread, one box of eggs, a bottle of cooking oil, and a frying pan, and
        make a breakfast/snack for your family/housemates.
      </p>
      <ol>
        <li>Beat eggs in a bowl with a tablespoon (maybe start with 4 eggs)</li>
        <li>Cut a slice of bread into two and dip them into the eggs</li>
        <li>Heat oil in a frying pan</li>
        <li>Toast the bread in the frying pan (see the video)</li>
      </ol>
      <div className="videoWrapper">
        <ReactPlayer
          style={{ left: "12.5%" }}
          width="75%"
          height="100%"
          url="https://www.facebook.com/fesfellowship/videos/1363082873878899/"
          className="react-player"
          controls
        />
      </div>
      <br />
    </div>
  );
}

export default Skills;
