import React from "react";
import { Row, Col, Image, Card } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  EmailIcon,
  EmailShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";

function BibleStudy() {
  return (
    <div>
      <h1>Videos</h1>
      <Card border="danger">
        <Card.Link
          href="https://groupministry.lifeway.com/2020/03/20/free-bible-study-videos-for-quarantined-groups/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={4}>
              <Image
                style={{ paddingTop: "37px" }}
                width="100%"
                src={
                  process.env.PUBLIC_URL +
                  "/Pictures/Free Bible Study Videos for Socially Distanced Groups.jpg"
                }
              />
            </Col>
            <Col>
              <Card.Body>
                <Card.Title id="clickable">
                  Free Bible Study Videos for Socially Distanced Groups
                </Card.Title>
                <Card.Text id="clickable">
                  For a limited time Lifeway is making several Bible studies
                  available through their streaming platform, Smallgroup by
                  LifeWay. There are a few ways to use these videos with your
                  group. Each member can watch the video on their own and then
                  discuss the content with your group in an online platform such
                  as Zoom, Google Hangouts, or Facebook Live. Or you can all
                  meet together on one of these platforms, have one person share
                  the video with the group using their laptop or other
                  electronic device, and then discuss as a group. Check out the
                  complete list of videos at the end of the post.
                </Card.Text>
                <FacebookShareButton url="https://groupministry.lifeway.com/2020/03/20/free-bible-study-videos-for-quarantined-groups/">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://groupministry.lifeway.com/2020/03/20/free-bible-study-videos-for-quarantined-groups/">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://groupministry.lifeway.com/2020/03/20/free-bible-study-videos-for-quarantined-groups/">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://groupministry.lifeway.com/2020/03/20/free-bible-study-videos-for-quarantined-groups/">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
          </Row>
        </Card.Link>
      </Card>
      <br />
      <Card border="danger">
        <Card.Link
          href="https://quentinschultze.com/12-videos-on-faith-and-communication/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={4}>
              <Image
                width="100%"
                src={process.env.PUBLIC_URL + "/Pictures/Quentin Schultze.png"}
              />
            </Col>
            <Col>
              <Card.Body>
                <Card.Title id="clickable">Faith and Communication</Card.Title>
                <Card.Text id="clickable">
                  “What is communication for?” “What is listening?” “Speak only
                  if you can improve upon the silence” During this period when
                  we stay at home, communication may be challenging for some of
                  us. May these short videos give us some insight (and
                  reminders!) to this important life skill.{" "}
                  <a
                    href="https://quentinschultze.com/wp-content/uploads/All-Outlines-Faith-Communication-Videos-1.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Each video is about 5 mins long (short!) and a PDF of
                    outlines for each video is available here!
                  </a>
                </Card.Text>
                <FacebookShareButton url="https://quentinschultze.com/12-videos-on-faith-and-communication/">
                  <FacebookIcon round size="30px" className="share" />
                </FacebookShareButton>
                <EmailShareButton url="https://quentinschultze.com/12-videos-on-faith-and-communication/">
                  <EmailIcon round size="30px" className="share" />
                </EmailShareButton>
                <WhatsappShareButton url="https://quentinschultze.com/12-videos-on-faith-and-communication/">
                  <WhatsappIcon round size="30px" className="share" />
                </WhatsappShareButton>
                <TelegramShareButton url="https://quentinschultze.com/12-videos-on-faith-and-communication/">
                  <TelegramIcon round size="30px" className="share" />
                </TelegramShareButton>
              </Card.Body>
            </Col>
          </Row>
        </Card.Link>
      </Card>
      <br />
      <h1>Bible Study Guides</h1>
      <Card border="danger">
        <Card.Link
          href="https://intervarsity.org/sites/default/files/COVID-19%20Grow%20Guide%20Bible%20Studies.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={4}>
              <Image
                width="100%"
                src={process.env.PUBLIC_URL + "/Pictures/Grow Guide.png"}
              />
            </Col>
            <Col>
              <Card.Body>
                <Card.Title id="clickable">
                  Covid-19 Grow Guide: Bible Studies
                </Card.Title>
                <Card.Text id="clickable">
                  Bible studies written to Christians during trial, hardship,
                  famine, persecution and … plagues
                </Card.Text>
              </Card.Body>
              <br />
              <br />
              <br />
              <FacebookShareButton url="https://intervarsity.org/sites/default/files/COVID-19%20Grow%20Guide%20Bible%20Studies.pdf">
                <FacebookIcon round size="30px" className="share" />
              </FacebookShareButton>
              <EmailShareButton url="https://intervarsity.org/sites/default/files/COVID-19%20Grow%20Guide%20Bible%20Studies.pdf">
                <EmailIcon round size="30px" className="share" />
              </EmailShareButton>
              <WhatsappShareButton url="https://intervarsity.org/sites/default/files/COVID-19%20Grow%20Guide%20Bible%20Studies.pdf">
                <WhatsappIcon round size="30px" className="share" />
              </WhatsappShareButton>
              <TelegramShareButton url="https://intervarsity.org/sites/default/files/COVID-19%20Grow%20Guide%20Bible%20Studies.pdf">
                <TelegramIcon round size="30px" className="share" />
              </TelegramShareButton>
            </Col>
          </Row>
        </Card.Link>
      </Card>
      <br />
      <Card border="danger">
        <Card.Link
          href="https://fessingapore.sharepoint.com/:b:/s/Curate/Ee9h6nbZNXxInlsQaFaZbwwB2poR76w7g6_4y_O6SMtUHw?e=YkkrG8"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={4}>
              <Image
                width="100%"
                src={process.env.PUBLIC_URL + "/Pictures/Neighbour.jpg"}
              />
            </Col>
            <Col>
              <Card.Body>
                <Card.Title id="clickable">Who is my Neighbour?</Card.Title>
                <Card.Text id="clickable">
                  Two short studies that help us reflect on Who is our
                  neighbour? or perhaps better put…to whom are we a neighbour?
                </Card.Text>
              </Card.Body>
              <br />
              <br />
              <FacebookShareButton url="https://fessingapore.sharepoint.com/:b:/s/Curate/Ee9h6nbZNXxInlsQaFaZbwwB2poR76w7g6_4y_O6SMtUHw?e=YkkrG8">
                <FacebookIcon round size="30px" className="share" />
              </FacebookShareButton>
              <EmailShareButton url="https://fessingapore.sharepoint.com/:b:/s/Curate/Ee9h6nbZNXxInlsQaFaZbwwB2poR76w7g6_4y_O6SMtUHw?e=YkkrG8">
                <EmailIcon round size="30px" className="share" />
              </EmailShareButton>
              <WhatsappShareButton url="https://fessingapore.sharepoint.com/:b:/s/Curate/Ee9h6nbZNXxInlsQaFaZbwwB2poR76w7g6_4y_O6SMtUHw?e=YkkrG8">
                <WhatsappIcon round size="30px" className="share" />
              </WhatsappShareButton>
              <TelegramShareButton url="https://fessingapore.sharepoint.com/:b:/s/Curate/Ee9h6nbZNXxInlsQaFaZbwwB2poR76w7g6_4y_O6SMtUHw?e=YkkrG8">
                <TelegramIcon round size="30px" className="share" />
              </TelegramShareButton>
            </Col>
          </Row>
        </Card.Link>
      </Card>
      <br />
      <Card border="danger">
        <Card.Link
          href="https://ifesworld.org/wp-content/uploads/2020/04/Be-Not-Afraid-My-Peace-I-Leave-with-You-IVPress.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={4}>
              <Image
                width="100%"
                src={process.env.PUBLIC_URL + "/Pictures/Peace.jpg"}
              />
            </Col>
            <Col>
              <Card.Body>
                <Card.Title id="clickable">
                  My Peace I Leave with You
                </Card.Title>
                <Card.Text id="clickable">
                  A week of Bible studies, focussed on Jesus words: “My Peace I
                  Leave with You”
                </Card.Text>
              </Card.Body>
              <br />
              <br />
              <br />
              <FacebookShareButton url="https://ifesworld.org/wp-content/uploads/2020/04/Be-Not-Afraid-My-Peace-I-Leave-with-You-IVPress.pdf">
                <FacebookIcon round size="30px" className="share" />
              </FacebookShareButton>
              <EmailShareButton url="https://ifesworld.org/wp-content/uploads/2020/04/Be-Not-Afraid-My-Peace-I-Leave-with-You-IVPress.pdf">
                <EmailIcon round size="30px" className="share" />
              </EmailShareButton>
              <WhatsappShareButton url="https://ifesworld.org/wp-content/uploads/2020/04/Be-Not-Afraid-My-Peace-I-Leave-with-You-IVPress.pdf">
                <WhatsappIcon round size="30px" className="share" />
              </WhatsappShareButton>
              <TelegramShareButton url="https://ifesworld.org/wp-content/uploads/2020/04/Be-Not-Afraid-My-Peace-I-Leave-with-You-IVPress.pdf">
                <TelegramIcon round size="30px" className="share" />
              </TelegramShareButton>
            </Col>
          </Row>
        </Card.Link>
      </Card>
      <br />
      <Card border="danger">
        <Card.Link
          href="https://intervarsity.org/sites/default/files/Encounters%20with%20Jesus%20-%20Online%20Bible%20Studies%20%5BFINAL%5D.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Row>
            <Col sm={4}>
              <Image
                width="100%"
                src={
                  process.env.PUBLIC_URL + "/Pictures/Encountering Jesus.jpg"
                }
              />
            </Col>
            <Col>
              <Card.Body>
                <Card.Title id="clickable">Encountering Jesus</Card.Title>
                <Card.Text id="clickable">
                  Online studies to help us encounter Jesus in our worries,
                  family relationships, areas that need healing, times of
                  uncertainty and isolation
                </Card.Text>
              </Card.Body>
              <br />
              <br />
              <FacebookShareButton url="https://intervarsity.org/sites/default/files/Encounters%20with%20Jesus%20-%20Online%20Bible%20Studies%20%5BFINAL%5D.pdf">
                <FacebookIcon round size="30px" className="share" />
              </FacebookShareButton>
              <EmailShareButton url="https://intervarsity.org/sites/default/files/Encounters%20with%20Jesus%20-%20Online%20Bible%20Studies%20%5BFINAL%5D.pdf">
                <EmailIcon round size="30px" className="share" />
              </EmailShareButton>
              <WhatsappShareButton url="https://intervarsity.org/sites/default/files/Encounters%20with%20Jesus%20-%20Online%20Bible%20Studies%20%5BFINAL%5D.pdf">
                <WhatsappIcon round size="30px" className="share" />
              </WhatsappShareButton>
              <TelegramShareButton url="https://intervarsity.org/sites/default/files/Encounters%20with%20Jesus%20-%20Online%20Bible%20Studies%20%5BFINAL%5D.pdf">
                <TelegramIcon round size="30px" className="share" />
              </TelegramShareButton>
            </Col>
          </Row>
        </Card.Link>
      </Card>
      <br />
    </div>
  );
}

export default BibleStudy;
