import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Prayers from "./Responses/Prayers";
import Hands from "./Responses/Hands";
import Skills from "./Responses/Skills";

class Responses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: <Prayers />,
    };
  }
  render() {
    const handleSelect = (eventKey) => {
      switch (eventKey) {
        case "prayers":
          this.setState({ content: <Prayers /> });
          break;
        case "hands":
          this.setState({ content: <Hands /> });
          break;
        case "skills":
          this.setState({ content: <Skills /> });
          break;
        default:
          this.setState({ content: <Prayers /> });
          break;
      }
    };
    return (
      <div>
        <Tabs
          defaultActiveKey="prayers"
          onSelect={handleSelect}
          className="subtab"
        >
          <Tab eventKey="prayers" title="Lift Our Prayers"></Tab>
          <Tab eventKey="hands" title="Lend Our Hands"></Tab>
          <Tab eventKey="skills" title="Level Up Our Skills"></Tab>
        </Tabs>
        {this.state.content}
      </div>
    );
  }
}

export default Responses;
