import React, { Component } from "react";
import { Image } from "react-bootstrap";
import Pagination from "react-js-pagination";

class Comic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [
        "Tim Wakeling-Zoom Easter.jpg",
        "Tim Wakeling",
        "https://tim.wakeling.me/cartoons/jesus-appears-to-his-disciples/",
      ],
      activePage: 1,
    };
  }
  render() {
    const comicMap = {
      1: [
        "Tim Wakeling-Zoom Easter.jpg",
        "Tim Wakeling",
        "https://tim.wakeling.me/cartoons/jesus-appears-to-his-disciples/",
      ],
      2: [
        "Altered States.jpg",
        "Lee Chee Chew",
        "https://www.straitstimes.com/opinion/cartoons/altered-states-hows-life-in-the-new-normal",
      ],
    };
    const handleChange = (pageNumber) => {
      this.setState({ activePage: pageNumber });
      this.setState({ content: comicMap[pageNumber] });
    };
    return (
      <div>
        <br />
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={1}
          totalItemsCount={2}
          itemClass="page-item"
          linkClass="page-link"
          hideNavigation
          hideFirstLastPages
          onChange={handleChange}
        />
        Comic by:{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={this.state.content[2]}
        >
          {this.state.content[1]}
        </a>
        <Image
          width="100%"
          src={process.env.PUBLIC_URL + "/Pictures/" + this.state.content[0]}
        />
      </div>
    );
  }
}

export default Comic;
