import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import BibleStudy from "./Resources/BibleStudy";
import Reflections from "./Resources/Reflections";
import Articles from "./Resources/Articles";

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: <Articles />,
    };
  }
  render() {
    const handleSelect = (eventKey) => {
      switch (eventKey) {
        case "biblestudy":
          this.setState({ content: <BibleStudy /> });
          break;
        case "reflections":
          this.setState({ content: <Reflections /> });
          break;
        case "articles":
          this.setState({ content: <Articles /> });
          break;
        default:
          this.setState({ content: <Articles /> });
          break;
      }
    };
    return (
      <div>
        <Tabs
          defaultActiveKey="articles"
          onSelect={handleSelect}
          className="subtab"
        >
          <Tab eventKey="articles" title="Articles"></Tab>
          <Tab eventKey="biblestudy" title="Bible Study Materials"></Tab>
          <Tab eventKey="reflections" title="Reflections"></Tab>
        </Tabs>
        {this.state.content}
      </div>
    );
  }
}

export default Resources;
