import React, { Component } from "react";
import InstagramEmbed from "react-instagram-embed";
import ReactPlayer from "react-player";
import { Card, Row, Col, Figure, Pagination } from "react-bootstrap";

class Stories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLang: "mandarin",
    };
  }
  render() {
    const story = {
      mandarin: [
        <div>
          <h2>
            <b>祂，一直都在</b>
          </h2>
          <h5>孙俪今, FES同工(华文组)</h5>
        </div>,
        <div
          style={{
            fontSize: "1.5rem",
          }}
        >
          <p>
            3月16号晚，马国政府宣布在两天后落实两星期的行动管制，以应对暴增的冠状病毒确诊人数。这段期间，国民禁止出国，游客禁止入境。为了保饭碗，许多马劳匆匆越堤，涌进新加坡，却来不及为自己寻找安顿之处。父亲每天往返新马工作已有数十年，他也在隔天早上匆匆越堤，但还好新加坡有亲戚愿意临时收留父亲落脚。除了不能一如既往的回家，父亲还能如常工作。那时的日子还是能过得下去。
          </p>
          <p>
            没料到，两星期后，轮到新国政府宣布一个月的阻断措施。父亲被逼停工，公司仍强制父亲留在新国，但家里又时不时报来让人忧心的消息。父亲焦心如焚，精神状况也渐渐叫人担忧，我却什么都做不了，只告诉自己：至少天天打一通电话回家，一通给父亲吧，希望能带来平安安慰的话。然而，每一次的通话结束都会伴随着强烈的无助感。“主啊，祢在哪里？祢睡了吗？”{" "}
          </p>
          <p>
            一天天过去，父亲和家里的状况没什么改善，但我却开始留意到父母亲一点一点的改变。先是分隔新柔两地的父母在有限的活动空间里，找到了自我省察的空间，甚至开始正视他们过去一直逃避的婚姻裂缝；后来，这样的独处空间更生出了他们对上帝同在的渴望。父母虽信主多年，但也已经离开教会好些年，直到去年才肯同我回到教会去。尽管如此，他们仍严禁我在他们面前提起“上帝”半字。岂料在两星期前，母亲竟和我们分享了她最近受感动的诗歌，之后父母也爽快地和我一起在电话里同心祷告。我开始略略体会到所有的艰难正是上帝显示祂自己的管道！
          </p>
          <p>
            第二天，新国政府继续缩紧政策，所有建筑业的工作准证和S准证的持有者都得在接下来的两星期遵守居家通知条例。持有建筑业工作准证的父亲这下可真要被困在屋里了。正当我开始担心父亲接下来的日子要怎么过时，他竟提醒我为他前一天祷告的事，尽管环境更艰难，但他对主的信靠在那晚的祷告后变得更坚定~
          </p>
          <p>
            我主我神啊，我在患难中寻求祢，祢就应允我，祢使凡仰望祢的人必不羞愧！虽我不知明天又会如何，但我的心哪，你仍要称颂耶和华！{" "}
          </p>
        </div>,
      ],
      english: [
        <div>
          <h3>
            <b></b>He Is Leading All The Way!
          </h3>
          <h6>Joyce Sune, FES staffworker(Chinese Work)</h6>
        </div>,
        <div
          style={{
            fontSize: "1.25rem",
          }}
        >
          <p>
            On the evening of 16 March, in response to the surge in the number
            of confirmed coronavirus cases, the Malaysian Government announced a
            two-week Movement Control Order (MCO) to be put in place within two
            days. During this period, Malaysian nationals are prohibited from
            leaving the country and tourists are prohibited from entering. In
            order to secure their jobs, many Malaysians hurriedly crossed the JB
            border and stayed in Singapore but it was difficult to find place to
            stay. My father, who has been commuting to work in Singapore for
            decades, hurried across the border the next morning, but fortunately
            there were relatives in Singapore willing to take him in
            temporarily. Though not being able to come home to JB daily, father
            was able to work as usual. Back then it was still possible to get
            by.
          </p>
          <p>
            Unexpectedly, two weeks later, Singapore government announced a
            month's long of Circuit Breaker measures. My father was forced to
            stop work and his company still forced him to stay in Singapore, but
            from time to time there was worrying news from my family (my mother
            and sisters are staying in JB). I could not do much but tell myself
            that I should at least call my father once a day, hoping that it
            would bring him peace and comfort. However, at the end of every
            call, it is often accompanied by a strong sense of helplessness.
            "Lord, where are you? Are you asleep?"
          </p>
          <p>
            Day by day, things did not get much better for my father and family,
            but I began noticing a change in my parents’ attitude little by
            little. First, my parents separated at two places found room for
            self-examination during the period where there is less activity.
            They even began to confront and discuss their marriage rift they had
            been avoiding in the past. Later, such solitude gave birth to a
            desire for God's presence. My parents have been out of the church
            for years, but only last year they were willing to go back to church
            with me. Nevertheless, they forbade me from mentioning the word
            "God" in their presence. To my surprise, two weeks ago, my mother
            shared with us a worship song she had recently been touched by, and
            my parents gladly joined me on the phone to pray in one heart. I
            begin to gradually appreciate that all the hardships are exactly the
            means through which God reveals Himself!
          </p>
          <p>
            The next day, Singapore government continued to tighten its policy,
            with all construction work permit and S permit holders having to
            comply with Stay Home Notice (SHN) regulations for the next two
            weeks. My father with a construction work permit is really stuck in
            the house. Just as I began to worry about what my father was going
            to do with the rest of his days, he went so far as to remind me of
            what I had prayed for him the day before, and despite the more
            difficult circumstances, his trust in the Lord grew stronger after
            that night's prayer.
          </p>
          <p style={{ textAlign: "center" }}>
            <i>
              I seek thee, O Lord my God, in my affliction,
              <br />
              and thou hast promised me,
              <br />
              that whosoever shall look upon thee shall not be ashamed!
              <br />
              And though I know not what tomorrow will bring,
              <br />
              yet, O my heart, praise thou the LORD!
            </i>
          </p>
        </div>,
      ],
    };
    const handleChange = (e) => {
      this.setState({ activeLang: e.target.id });
    };
    return (
      <div>
        <br />
        <h1>FES Stories</h1>
        <hr />
        <Row>
          <Col>
            <h3 style={{ textAlign: "center" }}>Beyond Our Shores</h3>
            <p>
              The pandemic situation in Singapore may have been better
              controlled now. How about our neighboring country? Here is a story
              of one student making attempts to help those affected by Covid-19
              at the remote places in Indonesia.
            </p>
            <div style={{ paddingBottom: "56.25%" }} className="videoWrapper">
              <ReactPlayer
                width="100%"
                height="100%"
                url="https://www.facebook.com/fesfellowship/videos/2655937101332045/"
                className="react-player"
                controls
              />
            </div>
          </Col>
          <Col>
            <h3 style={{ textAlign: "center" }}>Grappling with the Reality</h3>
            <p>
              Some of our Indonesian students went back to Jakarta during the
              university term break. Here is one, sharing his experience and
              plea for our prayer.
            </p>
            <br />
            <br />
            <div style={{ paddingBottom: "56.25%" }} className="videoWrapper">
              <ReactPlayer
                width="100%"
                height="100%"
                url="https://www.facebook.com/fesfellowship/videos/584307599189802/"
                className="react-player"
                controls
              />
            </div>
          </Col>
        </Row>

        <hr />
        <h3>NTUCF's Circuit Breaker Newsletter</h3>
        <div style={{ paddingBottom: "70%" }}>
          <iframe
            style={{ position: "absolute", width: "72.5%", height: "100%" }}
            title="Let Us Remember"
            src="https://online.pubhtml5.com/xvkh/fzjk/#p=1"
            allowFullScreen
          />
        </div>
        <hr />
        <h3>FES Singapore Instagram</h3>
        <Row>
          <Figure.Caption
            style={{ padding: "20px", textAlign: "center", width: "100%" }}
          >
            Stories of two students sharing their struggles and hopes amidst the
            ongoing pandemic.
          </Figure.Caption>
        </Row>
        <Row className="insta-row">
          <Col>
            <InstagramEmbed
              url="https://www.instagram.com/tv/B_mMUcWlrNb/"
              maxWidth={320}
              hideCaption={true}
              containerTagName="div"
              protocol=""
              injectScript
              id="insta"
            />
          </Col>
          <Col>
            <InstagramEmbed
              url="https://www.instagram.com/p/B_oS5o5FQWm/"
              maxWidth={320}
              hideCaption={true}
              containerTagName="div"
              protocol=""
              injectScript
              id="insta"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Figure.Caption style={{ padding: "20px" }}>
              Poly CF welcome back to school?
              <br />
              Poly CF goes back to school!
            </Figure.Caption>
            <InstagramEmbed
              url="https://www.instagram.com/p/B_XUmUyFaPv/"
              maxWidth={320}
              hideCaption={true}
              containerTagName="div"
              protocol=""
              injectScript
              id="insta"
            />
            <br />
          </Col>
          <Col>
            <Figure.Caption style={{ padding: "20px" }}>
              SIM CF sings "Pass It On" as a reminder that we can be a spreader
              of God's love to those around us
            </Figure.Caption>
            <InstagramEmbed
              url="https://www.instagram.com/tv/B-_ncKeFRIC/"
              maxWidth={320}
              hideCaption={true}
              containerTagName="div"
              protocol=""
              injectScript
              id="insta"
            />
            <br />
          </Col>
          <Col>
            <Figure.Caption style={{ padding: "20px" }}>
              Last FES staff gathering before Circuit Breaker
            </Figure.Caption>
            <InstagramEmbed
              url="https://www.instagram.com/p/B-53Wjkl9Ua/"
              maxWidth={320}
              hideCaption={true}
              containerTagName="div"
              protocol=""
              injectScript
              id="insta"
            />
          </Col>
        </Row>
        <hr />
        <div
          style={{
            width: "60%",
            left: "20%",
            position: "relative",
            textAlign: "justify",
          }}
        >
          <Row>
            <Col sm={8}>{story[this.state.activeLang][0]}</Col>
            <Col>
              <Pagination style={{ justifyContent: "flex-end" }}>
                <Pagination.Item
                  id="mandarin"
                  active={this.state.activeLang === "mandarin"}
                  onClick={handleChange}
                >
                  中文
                </Pagination.Item>
                <Pagination.Item
                  id="english"
                  active={this.state.activeLang === "english"}
                  onClick={handleChange}
                >
                  English
                </Pagination.Item>
              </Pagination>
            </Col>
          </Row>
          <br />
          {story[this.state.activeLang][1]}
        </div>
        <br />
        <hr />
        <h1>IFES Stories</h1>
        <h4 style={{ textAlign: "center" }}>FCS: Macau</h4>
        <div className="videoWrapper">
          <ReactPlayer
            style={{ left: "12.5%" }}
            width="75%"
            height="100%"
            controls
            url="https://www.youtube.com/embed/631BjJOHF48?start=55"
            className="react-player"
          />
        </div>
        <br />
        <hr />
        <h4 style={{ textAlign: "center" }}>IFES: East Asia</h4>
        <div className="videoWrapper">
          <ReactPlayer
            style={{ left: "12.5%" }}
            width="75%"
            height="100%"
            controls
            url="https://www.youtube.com/embed/6DSuwIWVs1c"
            className="react-player"
          />
        </div>
        <br />
        <hr />
        <h4 style={{ textAlign: "center" }}>Articles</h4>
        <Card style={{ width: "60%", marginLeft: "20%", textAlign: "center" }}>
          <Card.Img
            variant="top"
            src={
              process.env.PUBLIC_URL +
              "/Pictures/Unprecedented Changes - fadeout.jpg"
            }
          />
          <a
            href="https://ifesworld.org/en/blog/unprecedented-changes-in-the-university-world/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card.Header as="h6">Read the full article here!</Card.Header>
          </a>
        </Card>
        <br />
        <br />
        <Card style={{ width: "60%", marginLeft: "20%", textAlign: "center" }}>
          <Card.Img
            variant="top"
            src={
              process.env.PUBLIC_URL +
              "/Pictures/Voice of Mongolia - fadeout.jpg"
            }
          />
          <a
            href={process.env.PUBLIC_URL + "/PDFs/Voice of Mongolia.pdf"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card.Header as="h6">Read the full article here!</Card.Header>
          </a>
        </Card>
        <br />
        <br />
        <Card
          bg="warning"
          style={{ width: "60%", marginLeft: "20%", textAlign: "center" }}
        >
          <a
            id="yellowBtn"
            style={{ textAlign: "center" }}
            href="https://ifesworld.org/en/coronavirus/#stories"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card.Header as="h3">Click here for more IFES stories!</Card.Header>
          </a>
        </Card>
        <br />
      </div>
    );
  }
}

export default Stories;
