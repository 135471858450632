import React from "react";

function Codenames() {
  return (
    <div className="gameWrapper">
      <iframe
        title="Codenames"
        id="iframehtml5"
        width="100%"
        height="100%"
        src="https://www.horsepaste.com/"
        frameborder="0"
        border="0"
        scrolling="yes"
        class="iframe-default"
        allowfullscreen=""
      ></iframe>
    </div>
  );
}

export default Codenames;
