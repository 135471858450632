import React, { Component } from "react";
import { Col, Card, Image, Row } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  EmailIcon,
  EmailShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";

class Reflections extends Component {
  render() {
    return (
      <div>
        <h1>Reflections</h1>
        <Card border="success">
          <Card.Link
            href="https://static1.squarespace.com/static/59764bcb725e2575438613ad/t/5d485f3eba666100018de5af/1565024063056/Those+Flooded+By+Too+Much+Information.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Row>
              <Col sm={8}>
                <Card.Header id="clickable" as="h5">
                  A Liturgy For Those Flooded By Too Much Information
                </Card.Header>
                <Card.Body>
                  <Card.Text id="clickable">
                    In a world so wired and interconnected, our anxious hearts
                    are pummeled by an endless barrage of troubling news … Give
                    us discernment …
                  </Card.Text>
                  <br />
                  <FacebookShareButton url="https://static1.squarespace.com/static/59764bcb725e2575438613ad/t/5d485f3eba666100018de5af/1565024063056/Those+Flooded+By+Too+Much+Information.pdf">
                    <FacebookIcon round size="30px" className="share" />
                  </FacebookShareButton>
                  <EmailShareButton url="https://static1.squarespace.com/static/59764bcb725e2575438613ad/t/5d485f3eba666100018de5af/1565024063056/Those+Flooded+By+Too+Much+Information.pdf">
                    <EmailIcon round size="30px" className="share" />
                  </EmailShareButton>
                  <WhatsappShareButton url="https://static1.squarespace.com/static/59764bcb725e2575438613ad/t/5d485f3eba666100018de5af/1565024063056/Those+Flooded+By+Too+Much+Information.pdf">
                    <WhatsappIcon round size="30px" className="share" />
                  </WhatsappShareButton>
                  <TelegramShareButton url="https://static1.squarespace.com/static/59764bcb725e2575438613ad/t/5d485f3eba666100018de5af/1565024063056/Those+Flooded+By+Too+Much+Information.pdf">
                    <TelegramIcon round size="30px" className="share" />
                  </TelegramShareButton>
                </Card.Body>
              </Col>
              <Col sm={4}>
                <Image
                  width="100%"
                  src={
                    process.env.PUBLIC_URL +
                    "/Pictures/A Liturgy For Those Flooded By Too Much Information.jfif"
                  }
                />
              </Col>
            </Row>
          </Card.Link>
        </Card>
        <br />
        <Card border="success">
          <Card.Link
            href="https://www.nyack.edu/news/a-response-to-covid-19-exposed-by-sarah-bourns-20-ats/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Row>
              <Col sm={8}>
                <Card.Header id="clickable" as="h5">
                  Exposed - A poignant assessment of our world’s condition
                </Card.Header>
                <Card.Body>
                  <Card.Text id="clickable">
                    We’ve all been exposed.
                    <br />
                    Not necessarily to the virus
                    <br />
                    (though maybe…who knows)
                    <br />
                    We’ve all been exposed BY the virus.
                  </Card.Text>
                  <FacebookShareButton url="https://www.nyack.edu/news/a-response-to-covid-19-exposed-by-sarah-bourns-20-ats/">
                    <FacebookIcon round size="30px" className="share" />
                  </FacebookShareButton>
                  <EmailShareButton url="https://www.nyack.edu/news/a-response-to-covid-19-exposed-by-sarah-bourns-20-ats/">
                    <EmailIcon round size="30px" className="share" />
                  </EmailShareButton>
                  <WhatsappShareButton url="https://www.nyack.edu/news/a-response-to-covid-19-exposed-by-sarah-bourns-20-ats/">
                    <WhatsappIcon round size="30px" className="share" />
                  </WhatsappShareButton>
                  <TelegramShareButton url="https://www.nyack.edu/news/a-response-to-covid-19-exposed-by-sarah-bourns-20-ats/">
                    <TelegramIcon round size="30px" className="share" />
                  </TelegramShareButton>
                </Card.Body>
              </Col>
              <Col sm={4}>
                <Image
                  width="100%"
                  src={process.env.PUBLIC_URL + "/Pictures/Exposed.jpg"}
                />
              </Col>
            </Row>
          </Card.Link>
        </Card>
        <br />
        <Card border="success">
          <Card.Link
            href="https://odb-covid.org/dont-waste-the-lockdown/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Row>
              <Col sm={8}>
                <Card.Header id="clickable" as="h5">
                  Don’t Waste the Lockdown
                </Card.Header>
                <Card.Body>
                  <Card.Text id="clickable">
                    “Today, all our church services are delivered online … But
                    what would happen when we can return to our sanctuaries?
                    Will some get so used to viewing church services and hearing
                    sermons in the comfort of their homes that they stop
                    attending their churches?”
                    <br />
                    <br />
                    What good spiritual disciplines can we develop and what are
                    some bad habits we should not learn? …
                    <br />
                    <br />
                    What is the Lord whispering to us during this period?
                  </Card.Text>
                  <FacebookShareButton url="https://odb-covid.org/dont-waste-the-lockdown/">
                    <FacebookIcon round size="30px" className="share" />
                  </FacebookShareButton>
                  <EmailShareButton url="https://odb-covid.org/dont-waste-the-lockdown/">
                    <EmailIcon round size="30px" className="share" />
                  </EmailShareButton>
                  <WhatsappShareButton url="https://odb-covid.org/dont-waste-the-lockdown/">
                    <WhatsappIcon round size="30px" className="share" />
                  </WhatsappShareButton>
                  <TelegramShareButton url="https://odb-covid.org/dont-waste-the-lockdown/">
                    <TelegramIcon round size="30px" className="share" />
                  </TelegramShareButton>
                </Card.Body>
              </Col>
              <Col sm={4}>
                <Image
                  width="100%"
                  src={
                    process.env.PUBLIC_URL +
                    "/Pictures/Don’t Waste the Lockdown.jpg"
                  }
                />
              </Col>
            </Row>
          </Card.Link>
        </Card>
        <br />
        <Card border="success">
          <Card.Link
            href="https://www.christianitytoday.com/ct/2020/april-web-only/coronavirus-technology-creator-soul-zoom.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Row>
              <Col sm={8}>
                <Card.Header id="clickable" as="h5">
                  This Is Your Soul on Zoom
                </Card.Header>
                <Card.Body>
                  <Card.Text id="clickable">
                    Many technologies we cursed months ago for driving us apart
                    we now bless for holding us together.… There are reasons for
                    caution when it comes to the uses of technology…
                  </Card.Text>
                  <br />
                  <FacebookShareButton url="https://www.christianitytoday.com/ct/2020/april-web-only/coronavirus-technology-creator-soul-zoom.html">
                    <FacebookIcon round size="30px" className="share" />
                  </FacebookShareButton>
                  <EmailShareButton url="https://www.christianitytoday.com/ct/2020/april-web-only/coronavirus-technology-creator-soul-zoom.html">
                    <EmailIcon round size="30px" className="share" />
                  </EmailShareButton>
                  <WhatsappShareButton url="https://www.christianitytoday.com/ct/2020/april-web-only/coronavirus-technology-creator-soul-zoom.html">
                    <WhatsappIcon round size="30px" className="share" />
                  </WhatsappShareButton>
                  <TelegramShareButton url="https://www.christianitytoday.com/ct/2020/april-web-only/coronavirus-technology-creator-soul-zoom.html">
                    <TelegramIcon round size="30px" className="share" />
                  </TelegramShareButton>
                </Card.Body>
              </Col>
              <Col sm={4}>
                <Image
                  width="100%"
                  src={
                    process.env.PUBLIC_URL +
                    "/Pictures/This is your soul on zoom.jpg"
                  }
                />
              </Col>
            </Row>
          </Card.Link>
        </Card>
        <br />
        <Card border="success">
          <Card.Link
            href="https://fessingapore.sharepoint.com/:b:/s/Curate/EWFY0GvAiQRNsMTHgjluG3MBO5VMt0zxBZimZfbwUtb5qQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Row>
              <Col sm={8}>
                <Card.Header id="clickable" as="h5">
                  Sabbath in Times of Crisis
                </Card.Header>
                <Card.Body>
                  <Card.Text id="clickable">
                    A reflection written by Adrian Nugroho:
                    <br />
                    Sabbath is more than resting and replenishing our energy….
                    the one and only God who created everything out of nothing….
                    He is in control over our seemingly tough and uncertain
                    situations. And He…. was called, “the Lord of the Sabbath.”
                  </Card.Text>
                  <FacebookShareButton url="https://fessingapore.sharepoint.com/:b:/s/Curate/EWFY0GvAiQRNsMTHgjluG3MBO5VMt0zxBZimZfbwUtb5qQ">
                    <FacebookIcon round size="30px" className="share" />
                  </FacebookShareButton>
                  <EmailShareButton url="https://fessingapore.sharepoint.com/:b:/s/Curate/EWFY0GvAiQRNsMTHgjluG3MBO5VMt0zxBZimZfbwUtb5qQ">
                    <EmailIcon round size="30px" className="share" />
                  </EmailShareButton>
                  <WhatsappShareButton url="https://fessingapore.sharepoint.com/:b:/s/Curate/EWFY0GvAiQRNsMTHgjluG3MBO5VMt0zxBZimZfbwUtb5qQ">
                    <WhatsappIcon round size="30px" className="share" />
                  </WhatsappShareButton>
                  <TelegramShareButton url="https://fessingapore.sharepoint.com/:b:/s/Curate/EWFY0GvAiQRNsMTHgjluG3MBO5VMt0zxBZimZfbwUtb5qQ">
                    <TelegramIcon round size="30px" className="share" />
                  </TelegramShareButton>
                </Card.Body>
              </Col>
              <Col sm={4}>
                <Image
                  width="100%"
                  src={
                    process.env.PUBLIC_URL +
                    "/Pictures/Sabbath in times of crisis.jpg"
                  }
                />
              </Col>
            </Row>
          </Card.Link>
        </Card>
        <br />
        <Card border="success">
          <Card.Link
            href="https://faithandleadership.com/olivia-paschal-social-distancing-spiritual-practice"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Row>
              <Col sm={8}>
                <Card.Header id="clickable" as="h5">
                  Social Distancing as a Spiritual Practice
                </Card.Header>
                <Card.Body>
                  <Card.Text id="clickable">
                    The practice of social distancing makes me constantly aware
                    of how I am living as part of Christ’s body in the world, of
                    how I am making the world a better place for all those who
                    inhabit it. What is it that they need from me? How am I
                    loving them? … There is no fear in love -- indeed, we are
                    told, perfect love drives out fear. Reimagining social
                    distancing and isolation as a practice of love, not an
                    action driven by fear, has opened my mind to what this new,
                    temporary way of doing community might look like…
                  </Card.Text>
                  <FacebookShareButton url="https://faithandleadership.com/olivia-paschal-social-distancing-spiritual-practice">
                    <FacebookIcon round size="30px" className="share" />
                  </FacebookShareButton>
                  <EmailShareButton url="https://faithandleadership.com/olivia-paschal-social-distancing-spiritual-practice">
                    <EmailIcon round size="30px" className="share" />
                  </EmailShareButton>
                  <WhatsappShareButton url="https://faithandleadership.com/olivia-paschal-social-distancing-spiritual-practice">
                    <WhatsappIcon round size="30px" className="share" />
                  </WhatsappShareButton>
                  <TelegramShareButton url="https://faithandleadership.com/olivia-paschal-social-distancing-spiritual-practice">
                    <TelegramIcon round size="30px" className="share" />
                  </TelegramShareButton>
                </Card.Body>
              </Col>
              <Col sm={4}>
                <Image
                  style={{ paddingTop: "32px" }}
                  width="100%"
                  src={
                    process.env.PUBLIC_URL +
                    "/Pictures/Social Distancing as a Spiritual Practice.png"
                  }
                />
              </Col>
            </Row>
          </Card.Link>
        </Card>
        <br />
        <Card border="success">
          <Card.Link
            href="https://opinion.inquirer.net/129013/lament-and-question#ixzz6KIpmnZSz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Row>
              <Col sm={8}>
                <Card.Header id="clickable" as="h5">
                  Lament and Question
                </Card.Header>
                <Card.Body>
                  <Card.Text id="clickable">
                    “God is in control.”
                    <br />
                    “All things work together for good.”
                    <br />
                    “This too shall pass.”
                    <br />
                    “Don’t complain.”
                    <br />
                    <br />
                    “Why?”
                  </Card.Text>
                  <FacebookShareButton url="https://opinion.inquirer.net/129013/lament-and-question#ixzz6KIpmnZSz">
                    <FacebookIcon round size="30px" className="share" />
                  </FacebookShareButton>
                  <EmailShareButton url="https://opinion.inquirer.net/129013/lament-and-question#ixzz6KIpmnZSz">
                    <EmailIcon round size="30px" className="share" />
                  </EmailShareButton>
                  <WhatsappShareButton url="https://opinion.inquirer.net/129013/lament-and-question#ixzz6KIpmnZSz">
                    <WhatsappIcon round size="30px" className="share" />
                  </WhatsappShareButton>
                  <TelegramShareButton url="https://opinion.inquirer.net/129013/lament-and-question#ixzz6KIpmnZSz">
                    <TelegramIcon round size="30px" className="share" />
                  </TelegramShareButton>
                </Card.Body>
              </Col>
              <Col sm={4}>
                <Image
                  style={{ paddingTop: "17px" }}
                  width="100%"
                  src={
                    process.env.PUBLIC_URL + "/Pictures/Lament and Question.jpg"
                  }
                />
              </Col>
            </Row>
          </Card.Link>
        </Card>
        <br />
        <Card border="success">
          <Card.Link
            href="https://ifesworld.org/wp-content/uploads/2020/03/STUCK-AT-HOME-Biblical-reflections-for-students-in-isolation.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Row>
              <Col sm={8}>
                <Card.Header id="clickable" as="h5">
                  Stuck at Home
                </Card.Header>
                <Card.Body>
                  <Card.Text id="clickable">
                    Biblical reflections for students in Isolation
                  </Card.Text>
                  <br />
                  <br />
                  <FacebookShareButton url="https://ifesworld.org/wp-content/uploads/2020/03/STUCK-AT-HOME-Biblical-reflections-for-students-in-isolation.pdf">
                    <FacebookIcon round size="30px" className="share" />
                  </FacebookShareButton>
                  <EmailShareButton url="https://ifesworld.org/wp-content/uploads/2020/03/STUCK-AT-HOME-Biblical-reflections-for-students-in-isolation.pdf">
                    <EmailIcon round size="30px" className="share" />
                  </EmailShareButton>
                  <WhatsappShareButton url="https://ifesworld.org/wp-content/uploads/2020/03/STUCK-AT-HOME-Biblical-reflections-for-students-in-isolation.pdf">
                    <WhatsappIcon round size="30px" className="share" />
                  </WhatsappShareButton>
                  <TelegramShareButton url="https://ifesworld.org/wp-content/uploads/2020/03/STUCK-AT-HOME-Biblical-reflections-for-students-in-isolation.pdf">
                    <TelegramIcon round size="30px" className="share" />
                  </TelegramShareButton>
                </Card.Body>
              </Col>
              <Col sm={4}>
                <Image
                  width="100%"
                  src={process.env.PUBLIC_URL + "/Pictures/Stuck at Home.jpg"}
                />
              </Col>
            </Row>
          </Card.Link>
        </Card>
        <br />
        <Card border="success">
          <Card.Link
            href="https://intervarsity.org/online-resources/retreating-lord"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Row>
              <Col sm={8}>
                <Card.Header id="clickable" as="h5">
                  Spend a day with Jesus
                </Card.Header>
                <Card.Body>
                  <Card.Text id="clickable">
                    Take this opportunity to spend extended time with the Lord
                  </Card.Text>
                  <br />
                  <br />
                  <FacebookShareButton url="https://intervarsity.org/online-resources/retreating-lord">
                    <FacebookIcon round size="30px" className="share" />
                  </FacebookShareButton>
                  <EmailShareButton url="https://intervarsity.org/online-resources/retreating-lord">
                    <EmailIcon round size="30px" className="share" />
                  </EmailShareButton>
                  <WhatsappShareButton url="https://intervarsity.org/online-resources/retreating-lord">
                    <WhatsappIcon round size="30px" className="share" />
                  </WhatsappShareButton>
                  <TelegramShareButton url="https://intervarsity.org/online-resources/retreating-lord">
                    <TelegramIcon round size="30px" className="share" />
                  </TelegramShareButton>
                </Card.Body>
              </Col>
              <Col sm={4}>
                <Image
                  width="100%"
                  src={
                    process.env.PUBLIC_URL +
                    "/Pictures/Spend a day with Jesus.jpg"
                  }
                />
              </Col>
            </Row>
          </Card.Link>
        </Card>
        <br />
      </div>
    );
  }
}

export default Reflections;
