import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tabs, Tab, Navbar, Container, Image } from "react-bootstrap";
import "./App.css";
import Home from "./Tabs/Home";
import Stories from "./Tabs/Stories";
import Responses from "./Tabs/Responses";
import Resources from "./Tabs/Resources";
import Intermezzo from "./Tabs/Intermezzo";

const removeClock = () => {
  const clock = document.getElementsByClassName("flip-clock")[0];
  if (clock !== undefined) {
    clock.parentNode.removeChild(clock);
  }
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { content: <Home /> };
  }

  render() {
    const handleSelect = (eventKey) => {
      switch (eventKey) {
        case "home":
          this.setState({ content: <Home /> });
          break;
        case "stories":
          this.setState({ content: <Stories /> });
          removeClock();
          break;
        case "responses":
          this.setState({ content: <Responses /> });
          removeClock();
          break;
        case "resources":
          this.setState({ content: <Resources /> });
          removeClock();
          break;
        case "intermezzo":
          this.setState({ content: <Intermezzo /> });
          removeClock();
          break;
        default:
          this.setState({ content: <Home /> });
          break;
      }
    };
    return (
      <Container className="container">
        <Navbar className="Nav-bar" variant="dark">
          <Navbar.Brand className="navbarText" href="#home">
            <div id="navbar-special">Specially Curated Materials</div>
            <h2 style={{ marginBottom: "0" }}>FES Amidst Circuit Breaker</h2>
            <div id="navbar-sub">
              Serving and Working through Covid-19 Outbreak
            </div>
          </Navbar.Brand>
          <span className="navbarImgSpan">
            <Image
              className="navbarImg"
              width="200px"
              style={{ marginRight: "10px" }}
              src={process.env.PUBLIC_URL + "/Pictures/Navbar (1).jpg"}
            />
            <Image
              className="navbarImg"
              style={{ marginRight: "10px" }}
              width="200px"
              src={process.env.PUBLIC_URL + "/Pictures/Navbar (2).jpg"}
            />
            <Image
              className="navbarImg"
              width="200px"
              src={process.env.PUBLIC_URL + "/Pictures/Navbar (3).jpg"}
            />
          </span>
        </Navbar>
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          onSelect={handleSelect}
        >
          <Tab eventKey="home" title="Home"></Tab>
          <Tab eventKey="stories" title="Stories"></Tab>
          <Tab eventKey="responses" title="Responses"></Tab>
          <Tab eventKey="resources" title="Resources"></Tab>
          <Tab eventKey="intermezzo" title="Intermezzo"></Tab>
        </Tabs>
        {this.state.content}
        <Navbar className="footerbar" variant="dark">
          <Navbar.Brand className="footer">
            <small>
              &copy; Copyright 2020 Fellowship of Evangelical Students. All
              Rights Reserved.
            </small>
            <p style={{ fontSize: "16px" }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://fessingapore.org/disclaimer/"
                className="footerLinks"
              >
                Disclaimer
              </a>{" "}
              |{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://fessingapore.org/privacy-policy/"
                className="footerLinks"
              >
                Privacy
              </a>
            </p>
          </Navbar.Brand>
        </Navbar>
      </Container>
    );
  }
}

export default App;
