import React, { Component } from "react";
import ReactPlayer from "react-player";
import { Spinner, Card } from "react-bootstrap";

class FunnyVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCard: 3,
      playerDisplay: false,
    };
  }
  render() {
    const videoMap = {
      1: "https://youtu.be/HBW8jp7kMPQ",
      2: "https://youtu.be/4n1lxBxaSWM",
      3: "https://youtu.be/8TFcDESI2bc",
    };
    let content = videoMap[this.state.activeCard];
    const handleClick = (e) => {
      if (parseInt(e.target.id) !== this.state.activeCard) {
        this.setState({
          activeCard: parseInt(e.target.id),
          playerDisplay: false,
        });
      }
    };
    const playerReady = () => {
      this.setState({ playerDisplay: true });
    };
    return (
      <div>
        <br />
        <div className="videoWrapper">
          <div
            style={{
              display: this.state.playerDisplay ? "none" : "block",
              left: "50%",
              top: "50%",
              position: "absolute",
              width: "50px",
              height: "50px",
              msTransform: "translate(-50%, -50%)",
              transform: "translate(-50%, -50%)",
              transformOrigin: "bottom left",
            }}
          >
            <Spinner
              style={{ width: "50px", height: "50px" }}
              animation="grow"
            />
          </div>
          <ReactPlayer
            style={{
              display: this.state.playerDisplay ? "block" : "none",
              left: "12.5%",
            }}
            width="75%"
            height="100%"
            controls
            url={content}
            className="react-player"
            onReady={playerReady}
          />
        </div>
        <br />
        <Card
          border={this.state.activeCard === 3 ? "primary" : "secondary"}
          onClick={handleClick}
          id="clickable"
        >
          <Card.Body id={3}>
            <Card.Title id={3}>Kim Huat WFH Day 37: Sian Half</Card.Title>
            <Card.Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UC2vJyKnD6SBmZDFjO5O7KzA"
            >
              Source: mrbrown
            </Card.Link>
          </Card.Body>
        </Card>
        <br />
        <Card
          border={this.state.activeCard === 1 ? "primary" : "secondary"}
          onClick={handleClick}
          id="clickable"
        >
          <Card.Body id={1}>
            <Card.Title id={1}>
              Premium Lian CCB Talkshow! - Inaugural Episode
            </Card.Title>
            <Card.Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCqXMPBGi21slRVOLP6anHfg"
            >
              Source: The Michelle Chong Channel
            </Card.Link>
          </Card.Body>
        </Card>
        <br />
        <Card
          border={this.state.activeCard === 2 ? "primary" : "secondary"}
          onClick={handleClick}
          id="clickable"
        >
          <Card.Body id={2}>
            <Card.Title id={2}>
              Kim Huat Addresses the Nation: 21st April 2020
            </Card.Title>
            <Card.Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UC2vJyKnD6SBmZDFjO5O7KzA"
            >
              Source: mrbrown
            </Card.Link>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default FunnyVideos;
