import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import ReactPlayer from "react-player";

function Hands() {
  return (
    <div>
      <h1>Lend Our Hands</h1>
      <hr />
      <h3>Our Mission Continues</h3>
      <br />
      <Row>
        <Col>
          <div className="videoWrapper" style={{ paddingBottom: "56.25%" }}>
            <ReactPlayer
              width="100%"
              height="100%"
              url="https://youtu.be/PZvjE4RpBXk"
              className="react-player"
              controls
            />
          </div>
        </Col>
        <Col>
          <div className="videoWrapper" style={{ paddingBottom: "56.25%" }}>
            <ReactPlayer
              width="100%"
              height="100%"
              url="https://youtu.be/wg1jqYmyWdU"
              className="react-player"
              controls
            />
          </div>
        </Col>
      </Row>
      <br />
      <p>
        Circuit Breaker has come to its end in Singapore as we begin to open up
        step-by-step. The path ahead is still uncertain and continues to invite
        us to imagine how our life could be in the new normal. Nonetheless, our
        vision remains the same, i.e. to see a community of Christlike leaders
        who are salt and light on campus, in the church, and in society for the
        glory of God. We seek to witness through the Good News that we have
        received. Let us unite our heads, hearts, and hands for this purpose.
      </p>
      <hr />
      <h3>Join the Conversation – Rebooting the World</h3>
      <div className="videoWrapper">
        <ReactPlayer
          style={{ left: "12.5%" }}
          width="75%"
          height="100%"
          url="https://youtu.be/Mcbxjz-LAuU"
          className="react-player"
          controls
        />
      </div>
      <br />
      <p>
        Drawing on years of experience, Tearfund drafted a short paper to
        imagine how the world can change for the better post-covid-19 situation.
        The paper can be downloaded{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.tearfund.org/~/media/files/action_resources/the_world_rebooted/2020-tearfund-the-world-rebooted-en.pdf?la=en"
        >
          here
        </a>
        . It is an invitation to participate in the conversation both as an
        individual and a community, that aims to encourage us to consider how we
        may take part in the change in the days ahead. They also provide a{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.tearfund.org/~/media/files/action_resources/the_world_rebooted/2020-tearfund-the-world-rebooted-en-discussion-guide.pdf?la=en"
        >
          discussion guide
        </a>{" "}
        to kick-start the process.
      </p>
      <hr />
      <h3>Global Emergency Response for Covid-19 through World Vision</h3>
      <Row>
        <Col sm={5}>
          <Image
            style={{ width: "100%" }}
            src={process.env.PUBLIC_URL + "/Pictures/Survival Fund.png"}
          />
        </Col>
        <Col>
          <p>
            World Vision seeks to promote the health system in the poorest
            countries to prevent the spread of Covid-19 particularly among the
            children and their families. <br />
            Let us support their efforts.
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.worldvision.org.sg/en/ways-to-give/sector-funding/global-health-emergency-response-covid-19"
            >
              Find out more here!
            </a>
          </p>
        </Col>
      </Row>
      <hr />
      <h3>Share the Blessings</h3>
      <Row>
        <Col sm={5}>
          <Image
            style={{ width: "100%" }}
            src={process.env.PUBLIC_URL + "/Pictures/Majulah Belanja.png"}
          />
        </Col>
        <Col>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sheet2site.com/s/majulahbelanja/"
            >
              Click here to visit Majulah Belanja
            </a>
          </p>
          <ol>
            <li> See the immediate needs posted in the site</li>
            <li>Assess whether you can offer your support</li>
            <li>Submit your request to bless </li>
            <li>Arrange the delivery for matching support</li>
          </ol>
        </Col>
      </Row>
      <hr />
      <h3>Appreciating Our Delivery Heroes</h3>
      <Row>
        <Col sm={5}>
          <Image
            style={{ width: "100%" }}
            src={
              process.env.PUBLIC_URL + "/Pictures/Thank You Delivery Heroes.png"
            }
          />
        </Col>
        <Col>
          <p>
            Let’s give appreciation to the delivery people whose work enables us
            to stay safer indoors by printing one of the posters and sticking it
            on our front door.
          </p>
          <p>Let’s bring more smiles today.</p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.thankyoudeliveryheroes.com/"
            >
              Click here to find out how you can appreciate them!
            </a>
          </p>
        </Col>
      </Row>
      <hr />
      <h3>
        Assisting the Poor through Puso Ng Ama Foundation in the Philippines
      </h3>
      <Row>
        <Col sm={5}>
          <Image
            style={{ width: "100%" }}
            src={
              process.env.PUBLIC_URL + "/Pictures/Solidarity beyond borders.png"
            }
          />
        </Col>
        <Col>
          <p>
            Puso Ng Ama foundation in the Philippines partners Human Nature
            social enterprise to provide food for many poor families going
            hungry during lockdown there about two weeks ago.{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://saltandlight.sg/news/the-gospel-was-given-for-times-like-these-social-enterprise-in-philippines-feeding-thousands-going-hungry-in-lockdown/"
            >
              Read their story here.
            </a>
          </p>
          <p>
            While these people truly need our prayer, how about supporting the
            cause through a one-time donation for about S$ 12.00 (400 Pesos) to
            feed one family.
          </p>
          <p>
            Donations can be done through bank transfer or through{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://humanheartnature.com/buy/"
            >
              Human Nature Philippines website
            </a>
          </p>
        </Col>
      </Row>
      <hr />
      <h3>Supporting the Migrant Workers through HealthServe</h3>
      <Row>
        <Col sm={5}>
          <Image
            style={{ width: "100%" }}
            src={process.env.PUBLIC_URL + "/Pictures/healthserve.png"}
          />
        </Col>
        <Col>
          <p>
            HealthServe just launched this website on 13 April 2020 to support
            ongoing efforts to help educate migrant workers and provide remote
            counselling and casework support.
          </p>
          <p>
            <b>
              We can also use the hotline to submit messages of encouragement
              for the migrant workers which may be published on their upcoming
              Solidarity Wall.
            </b>
          </p>
          <p>
            HealthServe Hotline: +65 3138 4443
            <br />
            Official HealthServe COVID-19 Information Page:
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="covid19.healthserve.org.sg"
            >
              {" "}
              covid19.healthserve.org.sg
            </a>
          </p>
        </Col>
      </Row>
      <br />
    </div>
  );
}

export default Hands;
