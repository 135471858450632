import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Comic from "./Intermezzo/Comic";
import ScribblIO from "./Intermezzo/ScribblIO";
import Codenames from "./Intermezzo/Codenames";
import Spyfall from "./Intermezzo/Spyfall";
import ONUWerewolf from "./Intermezzo/ONU Werewolf";
import SecretHitler from "./Intermezzo/Secret Hitler";
import FunnyVideos from "./Intermezzo/Funny Videos";

class Intermezzo extends Component {
  constructor(props) {
    super(props);
    this.state = { content: <Comic /> };
  }
  render() {
    const handleSelect = (eventKey) => {
      switch (eventKey) {
        case "comic":
          this.setState({ content: <Comic /> });
          break;
        case "scribblIO":
          this.setState({ content: <ScribblIO /> });
          break;
        case "codenames":
          this.setState({ content: <Codenames /> });
          break;
        case "onuwerewolf":
          this.setState({ content: <ONUWerewolf /> });
          break;
        case "secrethitler":
          this.setState({ content: <SecretHitler /> });
          break;
        case "funnyvideos":
          this.setState({ content: <FunnyVideos /> });
          break;
        case "spyfall":
          this.setState({ content: <Spyfall /> });
          break;
        default:
          this.setState({ content: <Comic /> });
          break;
      }
    };
    return (
      <div>
        <Tabs
          defaultActiveKey="comic"
          onSelect={handleSelect}
          className="subtab"
        >
          <Tab eventKey="comic" title="Comic Strips"></Tab>
          <Tab eventKey="funnyvideos" title="Funny Videos"></Tab>
          <Tab eventKey="scribblIO" title="skribbl.io"></Tab>
          <Tab eventKey="codenames" title="Codenames"></Tab>
          <Tab eventKey="spyfall" title="Spyfall"></Tab>
          <Tab eventKey="onuwerewolf" title="ONU Werewolf"></Tab>
          <Tab eventKey="secrethitler" title="Secret Hitler"></Tab>
        </Tabs>
        {this.state.content}
      </div>
    );
  }
}

export default Intermezzo;
