import React from "react";

function Spyfall() {
  return (
    <div className="gameWrapper">
      <iframe
        title="Spyfall"
        id="iframehtml5"
        width="100%"
        height="100%"
        src="https://spyfall.adrianocola.com/"
        frameborder="0"
        border="0"
        scrolling="yes"
        class="iframe-default"
        allowfullscreen=""
      ></iframe>
    </div>
  );
}

export default Spyfall;
