import React, { Component } from "react";
import {
  Jumbotron,
  Card,
  Row,
  Col,
  Image,
  Button,
  Spinner,
} from "react-bootstrap";
import ReactPlayer from "react-player";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCard: 14,
      playerDisplay: false,
    };
    this.myRef = React.createRef();
  }
  render() {
    const videoMap = {
      1: [
        "Look out and Imagine!",
        "https://www.facebook.com/fesfellowship/videos/236764337705565/",
      ],
      2: [
        "安静？安定！ - Be Still? Be Firm!",
        "https://www.facebook.com/fesfellowship/videos/874252703014228/",
      ],
      3: [
        "Initiating Conversation",
        "https://www.facebook.com/fesfellowship/videos/609785379884136/",
      ],
      4: [
        "WFH…Wah!?",
        "https://www.facebook.com/fesfellowship/videos/769769283550317/",
      ],
      5: [
        "Seize the Moment",
        "https://www.facebook.com/fesfellowship/videos/2611919259086494/",
      ],
      6: [
        "莫忘此刻 - A Time to Remember",
        "https://www.facebook.com/fesfellowship/videos/542568916457076/",
      ],
      7: [
        "Reimagining the Ministry of Presence",
        "https://www.facebook.com/fesfellowship/videos/913933119028462/",
      ],
      8: [
        "Grieve with Meaning",
        "https://www.facebook.com/fesfellowship/videos/2679363839023095/",
      ],
      9: [
        "Cure for the Pandemic of Productivity & Consumerism",
        "https://www.facebook.com/fesfellowship/videos/2432347297056826/",
      ],
      10: [
        "Caught in the Storm",
        "https://www.facebook.com/fesfellowship/videos/675355753318919/",
      ],
      11: [
        "Facing the Perfect Storm",
        "https://www.facebook.com/fesfellowship/videos/242398587093873/",
      ],
      12: [
        "Rhythms, Boundaries and Habits",
        "https://www.facebook.com/fesfellowship/videos/923965058056101/",
      ],
      13: [
        "Wishful Thinking or Thanksgiving? Insights from Psalm 100",
        "https://www.facebook.com/fesfellowship/videos/2986542698128551/",
      ],
      14: [
        "Featured video: Beyond CB Period: After Nightmare and In Exile",
        "https://www.facebook.com/fesfellowship/videos/240064577446080/",
      ],
    };
    let content = videoMap[this.state.activeCard];
    const handleClick = (e) => {
      if (parseInt(e.target.id) !== this.state.activeCard) {
        this.setState({
          activeCard: parseInt(e.target.id),
          playerDisplay: false,
        });
        if (this.myRef.current) {
          console.log(this.myRef);
          window.scrollTo(0, this.myRef.current.offsetTop);
        }
      }
    };
    const playerReady = () => {
      this.setState({ playerDisplay: true });
    };
    return (
      <div>
        <Jumbotron id="jumbo">
          <p className="blockdate">
            <span> 15 April 2020 </span>
          </p>
          <h1>Hello There</h1>
          <p>
            This micro website is intended for FES staff, students, and
            graduates during this Circuit Breaker period.
          </p>
          <p>
            While we stay, work, and study from home, may the materials and
            resources specially curated and posted in this site help us to
            <ul>
              <li>think and reflect deeper,</li>
              <li>appreciate and treasure more what really matters,</li>
              <li>
                and imagine what our life and ministry look like post-Covid19
                outbreak.
              </li>
            </ul>
          </p>
          <p>
            Contents will be updated regularly throughout the Circuit Breaker
            measures period. If you have any suggestions or ideas aligned with
            the above objectives, feel free to contact{" "}
            <a href="mailto:yunmei@fellowship.sg">Yun Mei</a> or{" "}
            <a href="mailto:limjie@fellowship.sg">Lim Jie</a>
          </p>
          <p>
            We would love <b>your feedback!</b> Please spend 3 mins to do this{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.surveymonkey.com/r/CBMSfeedback"
            >
              <b>short survey</b>
            </a>
            !
          </p>
          <br />
          <br />
          <p className="blockdate">
            <span> 2 June 2020 </span>
          </p>
          <p>
            <b>Today marks the end</b> of the curation effort with the final
            update for this Circuit Breaker microsite, which has accompanied us
            for the past two months. However, feel free to revisit this site in
            the future if you would like to look for the resources or
            reflections that have been helpful for you.
          </p>
          <p>
            We hope to bring you another curation project in the future. See you
            then.
          </p>
          <h1>Goodbye</h1>
        </Jumbotron>
        <hr />
        <h2>
          <b>WFH</b>(Words from the Heart)
        </h2>
        <p>
          In this section, the Leadership Team of FES as well as graduates of
          FES will take turns to share their reflection and thoughts. Hope the
          videos posted here will assure, encourage, and energize you to emerge
          stronger and more mature in the Lord when the pandemic subsides.
        </p>
        <b style={{ textAlign: "center" }}>
          <h4>{content[0]}</h4>
        </b>
        <div className="videoWrapper" ref={this.myRef}>
          <div
            style={{
              display: this.state.playerDisplay ? "none" : "block",
              left: "50%",
              top: "50%",
              position: "absolute",
              width: "50px",
              height: "50px",
              msTransform: "translate(-50%, -50%)",
              transform: "translate(-50%, -50%)",
              transformOrigin: "bottom left",
            }}
          >
            <Spinner
              style={{ width: "50px", height: "50px" }}
              animation="grow"
            />
          </div>
          <ReactPlayer
            style={{
              display: this.state.playerDisplay ? "block" : "none",
              left:
                this.state.activeCard === 4
                  ? "38.134765625%"
                  : this.state.activeCard === 5 || this.state.activeCard === 10
                  ? "21.875%"
                  : this.state.activeCard === 8 || this.state.activeCard === 9
                  ? "18.359375%"
                  : this.state.activeCard === 14
                  ? "29.90625%"
                  : "12.5%",
            }}
            width={
              this.state.activeCard === 4
                ? "23.73046875%"
                : this.state.activeCard === 5 || this.state.activeCard === 10
                ? "56.25%"
                : this.state.activeCard === 8 || this.state.activeCard === 9
                ? "63.28125%"
                : this.state.activeCard === 14
                ? "42.1875%"
                : "75%"
            }
            height="100%"
            controls
            url={content[1]}
            className="react-player"
            onReady={playerReady}
          />
        </div>
        <br />
        <p
          style={{
            textAlign: "center",
            visibility: this.state.playerDisplay ? "visible" : "hidden",
          }}
        >
          <small>
            Remember to turn on captions! / English subtitles are available!
          </small>
        </p>
        <br />
        <Card border={this.state.activeCard === 14 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "37.5px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/Lisman 2.jpg"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                Beyond CB Period: After Nightmare and In Exile
                <br />
                <small>
                  The Circuit Breaker period is over. What must change and what
                  will remain? Lisman offers two reminders as we embark on the
                  possibly unknown future.
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={14}
                disabled={this.state.activeCard === 14}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Lisman Komaladi</Card.Title>
                <Card.Subtitle>FES General Secretary</Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Tuesday, 2 June 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <Card border={this.state.activeCard === 13 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "47px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/Tan Lai Yong 2.jpg"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                Wishful Thinking or Thanksgiving? Insights from Psalm 100
                <br />
                <small>
                  Do you sometimes get a little annoyed by someone who is very
                  quick to say "Praise the Lord"? Can we say that thanksgiving
                  is the sure pathway out of our troubles?
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={13}
                disabled={this.state.activeCard === 13}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Tan Lai Yong</Card.Title>
                <Card.Subtitle>
                  Former FES VP, currently Outreach & Community Engagement
                  Director, CAPT, NUS
                  <br />
                  Interviewed by Lee Yen Ter - a FES graduate
                </Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Tuesday, 26 May 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <Card border={this.state.activeCard === 12 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "38px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/Kwa Kiem Kiok.jpg"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                Rhythms, Boundaries and Habits
                <br />
                <small>
                  When everything seems to merge together, let us develop a
                  rhythm in our lives which will set healthy boundaries, which
                  then leads to good habits.
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={12}
                disabled={this.state.activeCard === 12}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Kwa Kiem Kiok</Card.Title>
                <Card.Subtitle>
                  Lecturer of Missiology and Interdisciplinary Studies at BGST
                </Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Tuesday, 19 May 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <Card border={this.state.activeCard === 11 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "38px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/Tan Lai Yong.png"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                Facing the Perfect Storm
                <br />
                <small>
                  David writes a psalm that describes a huge storm that sweeps
                  across his land. The storm is powerful , frightening, majestic
                  and powerful. How then should we respond in such a devastating
                  storm?
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={11}
                disabled={this.state.activeCard === 11}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Tan Lai Yong</Card.Title>
                <Card.Subtitle>
                  Former FES VP, currently Outreach & Community Engagement
                  Director, CAPT, NUS
                </Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Friday, 15 May 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <Card border={this.state.activeCard === 10 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "38px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/Kua Wee Seng.jpg"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                Caught in the Storm
                <br />
                <small>
                  The pandemic has hit the world and impacted us personally like
                  a storm. What can we learn from the disciples who were caught
                  in the storm on the Sea of Galilee?
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={10}
                disabled={this.state.activeCard === 10}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Kua Wee Seng</Card.Title>
                <Card.Subtitle>FES Vice President</Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Tuesday, 12 May 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <Card border={this.state.activeCard === 9 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "38px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/Jasmine Foo.jpg"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                Cure for the Pandemic of Productivity & Consumerism
                <br />
                <small>
                  As we eagerly anticipate a cure/vaccine for COVID-19, perhaps
                  the disease itself is a cure for the underlying social/global
                  problems of our economic structures.
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={9}
                disabled={this.state.activeCard === 9}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Jasmine Foo</Card.Title>
                <Card.Subtitle>
                  Former FES Staff, now studies at King's College, London
                </Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Friday, 8 May 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <Card border={this.state.activeCard === 8 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "38px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/Tan Soo Inn.jpg"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                Grieve with Meaning
                <br />
                <small>
                  In the face of the present pandemic, we remember two
                  realities: 1) we are human beings and 2) we are followers of
                  Jesus. How do these two realities intertwine, to help us to be
                  anchored in the storm and to throw out lifelines to others?
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={8}
                disabled={this.state.activeCard === 8}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Tan Soo Inn</Card.Title>
                <Card.Subtitle>Graceworks Chairperson</Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Friday, 1 May 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <Card border={this.state.activeCard === 7 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "38px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/jeremiah.jpg"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                Reimagining the Ministry of Presence
                <br />
                <small>
                  In this time when we are not allowed to meet face-to-face on
                  campus and enjoy each others' presence, Jeremiah invites us to
                  reimagine how student ministry can continue to work wherever
                  we are forced to be.
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={7}
                disabled={this.state.activeCard === 7}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Jeremiah Goh</Card.Title>
                <Card.Subtitle>
                  Head of English Section and Communications
                </Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Tuesday, 5 May 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <Card border={this.state.activeCard === 6 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "38px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/SookChing.jpg"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                莫忘此刻 - A Time to Remember
                <br />
                <small>
                  This pandemic caused you and me to Stay Home, WFH, which
                  brought lots of changes into our lives. Wear mask, wash hands,
                  keep social distancing. But this moment of time could also be
                  a blessing in our lives - remember this time.
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={6}
                disabled={this.state.activeCard === 6}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Chan Sook Ching</Card.Title>
                <Card.Subtitle>Senior Staff – Chinese Work</Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Friday, 1 May 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <Card border={this.state.activeCard === 5 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "50px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/mike.jpg"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                Seize the Moment
                <br />
                <small>
                  Without CoViD-19 or the Circuit Breaker, life is already so
                  difficult, or as in biblical lingo, life is futile. So what
                  does Ecclesiastes have to say about life on this side of
                  eternity that is filled with futility? How we can apply the
                  "Carpe Diem" concept in our day to day life, especially in
                  this time of crisis?
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={5}
                disabled={this.state.activeCard === 5}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Michael Kang</Card.Title>
                <Card.Subtitle>Head of Graduates Ministry</Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Tuesday, 28 April 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <Card border={this.state.activeCard === 4 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "38px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/darryl.jpeg"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                WFH…Wah!?
                <br />
                <small>
                  Being in awe and working from home does not seem compatible.
                  Through the seemingly little things in our life and
                  cultivating relationship, however, we may find that they are
                  actually not that far
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={4}
                disabled={this.state.activeCard === 4}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Darryl Putra</Card.Title>
                <Card.Subtitle>Head of Indonesian Ministry</Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Friday, 24 April 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <Card border={this.state.activeCard === 3 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "38px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/victor.jpg"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                Initiating Conversation
                <br />
                <small>
                  There are many conversations that we can participate in.
                  Victor invites us to consider two kinds of conversation that
                  we should be involved in...
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={3}
                disabled={this.state.activeCard === 3}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Victor Wibowo</Card.Title>
                <Card.Subtitle>
                  Head of Personnel, Resources, and Training
                </Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Tuesday, 21 April 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <Card border={this.state.activeCard === 2 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "38px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/SzeKhiong.jpg"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                安静？安定！ - Be Still? Be Firm!
                <br />
                <small>
                  我们在现今的状况下不是动弹不得，我们仍有天父邀请我们的空间（诗篇46篇10节）。
                  这也是大家再次思想身为上帝儿女祭司的身份与职分（彼得前书2章9节）。
                  <a
                    href="https://tinyurl.com/MigrantNeeds-Covid19"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    帮助客工 Google Doc
                  </a>
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={2}
                disabled={this.state.activeCard === 2}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Lai Sze Khiong</Card.Title>
                <Card.Subtitle>Head of Chinese Work</Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Friday, 17 April 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <Card border={this.state.activeCard === 1 ? "primary" : "secondary"}>
          <Row>
            <Col sm={2}>
              <Image
                style={{
                  width: "10rem",
                  marginLeft: "10px",
                  marginTop: "38px",
                }}
                variant="top"
                src={process.env.PUBLIC_URL + "/Pictures/lisman.jpg"}
                roundedCircle
              />
            </Col>
            <Col sm={10}>
              <Card.Header as="h5">
                Look out and Imagine!
                <br />
                <small>
                  After more than a week of working/studying from home, we may
                  experience increasing irritability and frustration. Lisman
                  Komaladi offers two antidotes to those symptoms.
                </small>
              </Card.Header>
              <Button
                className="watchBtn"
                variant="secondary"
                onClick={handleClick}
                id={1}
                disabled={this.state.activeCard === 1}
              >
                Watch now!
              </Button>
              <Card.Body>
                <Card.Title>Lisman Komaladi</Card.Title>
                <Card.Subtitle>FES General Secretary</Card.Subtitle>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">Tuesday, 14 Apr 2020</small>
              </Card.Footer>
            </Col>
          </Row>
        </Card>
        <br />
        <h2>Upcoming Events:</h2>
        <Image
          style={{ marginLeft: "25%" }}
          width="50%"
          src={process.env.PUBLIC_URL + "/Pictures/Student Outreach.jpeg"}
        />
        <br />
        <h4 style={{ textAlign: "center" }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://tinyurl.com/ifeseaweb29may"
          >
            Click here to sign up!
          </a>
        </h4>
        <br />
      </div>
    );
  }
}

export default Home;
